import React from 'react'
import { CKEditor } from 'ckeditor4-react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
function TextEditorModal(props) {
    console.log("ckeditor loaded")
    const [isLoading, setLoading] = React.useState(false);
    const [ckeditorData, setCkEditorData] = React.useState(props.initData);
    const handleClose = () => {
        setLoading(false);
        props.closeModal(false);
    };
    const handleSubmit = () => {
        console.log(ckeditorData);
        props.handleSubmit(ckeditorData);
        setLoading(true);
    }
    const handlechange = (event) => {
        setCkEditorData(event.editor.getData());
    }
    const checkData = (event) => {
        event.editor.setData(props.initData)
    }
    React.useEffect(() => {
        setLoading(false);
    }, [props.initData, props.toggle])
    return (<>
        <Dialog
            disableEnforceFocus
            fullScreen
            open={props.open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {props.heading}
            </DialogTitle>
            <DialogContent>
                <CKEditor name='ckeditor' config={{
                    codeSnippet_theme: "monokai",
                    height: 350,
                    mathJaxLib:
                        "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML",
                }} editorUrl="https://examprep.asworkstation.co.in/public/ckeditor/ckeditor.js" onInstanceReady={checkData} onChange={handlechange} />
            </DialogContent>
            <DialogActions sx={{ mx: 2 }}>
                <Button variant="outlined" color="error" onClick={handleClose}>
                    Cancel
                </Button>
                <LoadingButton loading={isLoading} variant="contained" color="info" onClick={handleSubmit} autoFocus>
                    {props.buttonName}
                </LoadingButton>
            </DialogActions>
        </Dialog>

    </>);
}

export default TextEditorModal;

