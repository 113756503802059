import { Alert, Autocomplete, Avatar, Box, Chip, CircularProgress, Container, Grid, Stack, Switch, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import ApiConfig from '../config/apiConfig';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';
import { GlobalContext } from './../contexts/globalContext';
var timeoutId;
const TabEditModal = React.memo(({ editMode, title, tabData, modalopen, setModalOpenParent, savedataToParent, saveOrUpdateButtonName, response }) => {
    const { userdata } = React.useContext(GlobalContext);
    const [saveOrUpdate, setsaveOrUpdate] = React.useState(false);

    const [thisTabData, setTabDetails] = React.useState({});
    const [initUsers, setInitUsers] = React.useState([]);
    const [searchUsers, setSearchUsers] = React.useState([]);
    const [searchLoading, setSearchLoading] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [fetchingAssignDetails, setFetchingAssignDetails] = React.useState({
        isFetching: true,
        textLebel: "Fetching assigned users..."
    })
    //search debouncing---------------------------------------

    const debounce = (func, delay = 200) => {
        return function () {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, arguments);
            }, delay);
        }
    };

    function search(searchText) {
        console.log('called', searchText, searchUsers); // true
        if (searchUsers.filter(obj => obj.full_name.toLowerCase().includes(searchText.toLowerCase())).length === 0) {
            setSearchLoading(true);
            axios({
                method: 'get',
                url: ApiConfig.url + "/user/search?name=" + searchText + "&c_user_id=" + userdata.user_id,
            }).then(function (response) {
                if (response.data.success === true) {
                    setSearchUsers([...searchUsers, ...response.data.data]);
                    console.log(response);
                }
                setSearchLoading(false);

            }).catch(err => {
                console.log(err);
                setSearchLoading(false);
                // setErrorMsg("Unexpected error occured. Failed to fetch.")
            })
        }
    }

    const debouncedSearch = debounce(search, 500);

    const handleSearchChange = (e) => {
        debouncedSearch(e.target.value);
    }
    //--------------------------------------------------------
    console.log("TabEditModal rendered");

    const handleClose = () => {
        // response.msg = false;
        setModalOpenParent(false);
    };
    const HandleSaveTabDetails = () => {
        if (thisTabData.tab_name === '') {
            setErrors(['Tab Name is Required.']);
            return;
        } else {
            setErrors([]);
        }
        let newObj = thisTabData;
        newObj.assigned_to = initUsers.map(obj => obj.id);
        savedataToParent(newObj);
    }
    const handleChange = (e) => {
        if (e.target.name === 'tab_name') {
            if (e.target.value.includes("  ")) {
                alert("Double spaces are not allowed");
                return false;
            }
            setTabDetails({ ...thisTabData, [e.target.name]: e.target.value, 'tab_link': '/' + e.target.value.toLowerCase().replace(" ", "-") });
        } else {
            setTabDetails({ ...thisTabData, [e.target.name]: e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value });
        }
    }
    React.useEffect(() => {
        setsaveOrUpdate(false);
        setErrors([]);
        setInitUsers([]);
        setSearchUsers([]);
        setTabDetails({ ...tabData });

        if (tabData && tabData.assigned_to.length > 0) {
            setSearchLoading(true);
            axios({
                method: 'post',
                url: ApiConfig.url + "/user/search/ById",
                data: {
                    user_id: userdata.user_id,
                    user_id_array: tabData.assigned_to,
                }
            }).then(function (response) {
                if (response.data.success === true) {
                    setInitUsers([...response.data.data]);
                    console.log(response);
                }
                setSearchLoading(false);
                setFetchingAssignDetails({
                    isFetching: false,
                    textLebel: "Select users to share this Tab"
                })

            }).catch(err => {
                console.log(err);
                setSearchLoading(false);
                setFetchingAssignDetails({
                    isFetching: false,
                    textLebel: "Error Fetching data.Please Reload."
                })
                // setErrorMsg("Unexpected error occured. Failed to fetch.")
            })
        }
        return () => {
            clearTimeout(timeoutId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabData]);

    const onSelectChange = (e, newVal) => {
        console.log(newVal)
        setInitUsers([...newVal]);
    }
    return (
        <>
            <Container>
                <Dialog

                    fullWidth
                    maxWidth={'sm'}
                    open={modalopen}
                    // onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        <Stack spacing={2}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="Tab Name"
                                            onChange={handleChange}
                                            name="tab_name"
                                            value={thisTabData?.tab_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            disabled
                                            fullWidth
                                            variant="standard"
                                            label="Tab Link"
                                            name="tab_link"
                                            value={thisTabData?.tab_link}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <PublicTwoToneIcon color={'success'} />Public&nbsp;
                                            <Switch name='isPrivate' checked={thisTabData?.isPrivate === 1} color="secondary" onChange={handleChange} />
                                            <SecurityTwoToneIcon color={'error'} />Private&nbsp;
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <VisibilityOffTwoToneIcon color={'success'} />Hide&nbsp;
                                            <Switch name='isShown' checked={thisTabData?.isShown === 1} color="secondary" onChange={handleChange} />
                                            <VisibilityTwoToneIcon color={'secondary'} />Show&nbsp;
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                            <br />
                            <Autocomplete
                                multiple
                                disabled={thisTabData?.isPrivate === 0}
                                id="size-small-outlined-multi"
                                size="small"
                                options={searchUsers}
                                loading={searchLoading}
                                autoHighlight
                                onChange={onSelectChange}
                                loadingText='Fetching users...'
                                noOptionsText='No such user.'
                                getOptionLabel={(option) => option?.full_name}
                                value={initUsers}
                                renderTags={(tagValue, getTagProps) => {
                                    return tagValue.map((option, index) => (
                                        <Chip {...getTagProps({ index })} label={option.full_name} avatar={<Avatar>{option.full_name.split('')[0]}</Avatar>} />
                                    ));
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        {<Avatar sx={{ height: 30, width: 30 }}>{option.full_name.split('')[0]}</Avatar>} &nbsp;{option.full_name}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField  {...params} onKeyUp={handleSearchChange} label={fetchingAssignDetails.textLebel} placeholder="Users"
                                        InputProps={{
                                            ...params?.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    &nbsp;{params?.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />

                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ m: 2 }}>
                        <Button size="small" color="error" onClick={handleClose}>
                            Cancel
                        </Button>
                        <LoadingButton size="small" variant="contained" loading={saveOrUpdate} color="secondary" onClick={HandleSaveTabDetails} autoFocus>
                            {saveOrUpdateButtonName}
                        </LoadingButton>
                    </DialogActions>
                    {
                        errors.length > 0 && errors.map(error =>
                            <Alert key={error} sx={{ m: 2.5 }} severity='error'>
                                {error}
                            </Alert>
                        )
                    }
                </Dialog>
            </Container>
        </>
    );
})

export default TabEditModal;