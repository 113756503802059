import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import maleImage from './../../logo/male.png';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import BadgeIcon from '@mui/icons-material/Badge';
import EventIcon from '@mui/icons-material/Event';
import { GlobalContext } from './../../contexts/globalContext';
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function ProfileSettings() {
    const { userdata } = React.useContext(GlobalContext);
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1} wrap="wrap">
                <Grid item xs={12} md={3}>
                    <Item>
                        <img height={230} src={maleImage} alt="ProfilePhoto" />
                    </Item>
                </Grid>
                <Grid item xs={12} md={9} >
                    <Item sx={{ overflowX: 'auto', p: 2 }}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <b> <ListItemText primary={userdata.full_name} secondary="Name" /></b>
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <EmailIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <b> <ListItemText primary={userdata.user_email} secondary="Email" /></b>
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <BadgeIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <b> <ListItemText primary={userdata.user_name} secondary="User Name" /></b>
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <EventIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <b><ListItemText primary={new Date(userdata.datetime).toLocaleString('en-GB', { hour12: true })} secondary="Account created at" /></b>
                        </ListItem>
                    </Item>
                </Grid>
            </Grid >
        </Box >
    );
}
