import { Box, Button, Fade, IconButton, Menu, MenuItem, Skeleton, Stack, Typography } from "@mui/material";
import SecuritySharpIcon from '@mui/icons-material/SecuritySharp';
import PublicSharpIcon from '@mui/icons-material/PublicSharp';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { GlobalContext } from "../contexts/globalContext";
import * as React from 'react';
import TutorialStudies from './../logo/tutorialstudies.png';
import { Link } from "react-router-dom";
function DesktopMenu() {
    const { userdata } = React.useContext(GlobalContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Link to={"/index"}>
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ display: { xs: 'block', sm: 'block' }, mr: 3 }}
                >
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <img height={70} src={TutorialStudies} alt="Tutorial Studies" />
                    </Box>
                </Typography>
            </Link>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, overflowX: 'hidden' }}>
                {userdata.routes.length > 0 && userdata.routes.filter(page => page[4] === 0).slice(0, 5).map((page) => (
                    <Link to={`${page[1]}`} key={page[0]}>
                        <Button startIcon={page[6] ? <SecuritySharpIcon /> : <PublicSharpIcon />}
                            sx={{ color: 'white', ml: 1, width: 'max-content' }}
                        >
                            {page[0]}
                        </Button>
                    </Link>
                ))}
                {
                    userdata.routes.length === 0 && userdata.login === true && (
                        <Stack direction="row" spacing={4}>
                            {
                                Array.from(Array(5)).map(() =>
                                    <Skeleton animation="wave" variant="text" width={120} height={50} />
                                )
                            }
                        </Stack>
                    )
                }



                {
                    userdata.routes.filter(page => page[4] === 0).length > 5 &&
                    <>
                        <IconButton aria-label="Open Extra tabs"
                            onClick={handleClick} color="inherit">
                            {
                                open ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />
                            }
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            {
                                userdata.routes.filter(page => page[4] === 0).slice(5).map((page) => (
                                    <Link to={`${page[1]}`} key={page[0]}><MenuItem onClick={handleClose}>{page[6] ? <SecuritySharpIcon /> : <PublicSharpIcon />} &nbsp;{page[0]}</MenuItem></Link>
                                ))
                            }


                        </Menu>

                    </>
                }


            </Box>
        </>
    );
}

export default DesktopMenu;