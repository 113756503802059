import { Autocomplete, Avatar, Box, Chip, CircularProgress, TextField } from "@mui/material";
import React from "react";

function MultipleTextInput({ isPrivate, searchUsers, searchLoading, onSelectChange, initUsers, handleSearchChange, fetchingAssignDetails }) {
    React.useEffect(() => {

    }, []);
    return (
        <Autocomplete
            multiple
            disabled={isPrivate === 0}
            id="size-small-outlined-multi"
            size="small"
            options={searchUsers}
            loading={searchLoading}
            autoHighlight
            onChange={onSelectChange}
            loadingText='Fetching users...'
            noOptionsText='No such user.'
            getOptionLabel={(option) => option?.full_name}
            value={initUsers}
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                    <Chip {...getTagProps({ index })} label={option.full_name} avatar={<Avatar>{option.full_name.split('')[0]}</Avatar>} />
                ));
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {<Avatar sx={{ height: 30, width: 30 }}>{option.full_name.split('')[0]}</Avatar>} &nbsp;{option.full_name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField  {...params} onKeyUp={handleSearchChange} label={fetchingAssignDetails.textLebel} placeholder="Users"
                    InputProps={{
                        ...params?.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                &nbsp;{params?.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export default MultipleTextInput;