import { setTopicArray } from "../cacheStorage";

export const initState = {
    tab_data: [],
    currentUserId: 0,
    TopicData: [],
    isLoading: false,
    errMsg: ""

};



export function TabListReducer(state, action) {
    let newTopicData = [];
    switch (action.type) {
        case 'SET_TOPIC_DATA':
            return { ...state, TopicData: action.payload.data };
        case 'ADD_TOPIC_DATA':
            let d = [...state.TopicData, action.payload.data];
            setTopicArray(action.payload.topic_id, d);
            return { ...state, TopicData: d };
        case 'UPDATE_TOPIC_DATA':
            newTopicData = state.TopicData.map(x => (x.id === action.payload.data.id ? {
                ...x,
                isPrivate: action.payload.data.isPrivate,
                topic_name: action.payload.data.topic_name,
                topic_desc: action.payload.data.topic_desc,
                assigned_to: JSON.stringify(action.payload.data.assigned_to)
            } : x));
            setTopicArray(action.payload.topic_id, newTopicData);
            return { ...state, TopicData: newTopicData };
        case 'DELETE_TOPIC_DATA':
            newTopicData = state.TopicData.filter(item => item.id !== action.payload.id);
            newTopicData = newTopicData.length > 0 ? newTopicData : [];
            setTopicArray(action.payload.topic_id, newTopicData);
            return { ...state, TopicData: newTopicData };
        case 'SET_ERROR_AND_LOADING':
            return {
                ...state,
                isLoading: action.payload.isLoading,
                errMsg: action.payload.errMsg
            }
        case 'SET_ALL':
            return {
                ...state,
                tab_data: action.payload.tab_data,
                currentUserId: action.payload.currentUserId,
                TopicData: action.payload.data,
                isLoading: action.payload.isLoading,
                errMsg: action.payload.errMsg
            }
        case 'IS_LOADING':
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        default:
            return initState;
    }
}


//edit data

export const editDataState = {
    isOpen: false,
    topic_id: 0,
    topic_name: '',
    topic_desc: '',
    isPrivate: 0,
    assigned_to: [],
}
export function TabListEditReducer(state, action) {
    switch (action.type) {
        case 'SET_EDIT_MODAL_DATA':
            return {
                topic_id: action.payload.id,
                isOpen: action.payload.isOpen,
                topic_name: action.payload.topic_name,
                topic_desc: action.payload.topic_desc,
                isPrivate: action.payload.isPrivate,
                assigned_to: action.payload.assigned_to,
            };
        case 'OPEN_EDIT_MODAL':
            return {
                ...state,
                isOpen: true
            };
        case 'CLOSE_EDIT_MODAL':
            return {
                ...state,
                isOpen: false
            };
        default:
            return editDataState;
    }
}