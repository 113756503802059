export const initState = {
    isFetching: false,
    textLebel: "Select users to share this Tab",
    isSearchLoading: false,
    topic_name: '',
    topic_desc: '',
    isPrivate: 0,
    assigned_to: [],
    searchUsers: [],
}

export function EditModalReducer(state, action) {
    switch (action.type) {
        case 'SET_MODAL_DATA':
            return {
                isFetching: action.payload.isFetching,
                textLebel: action.payload.textLebel,
                isSearchLoading: action.payload.isSearchLoading,
                topic_name: ('topic_name' in action.payload) ? action.payload.topic_name : state.topic_name,
                topic_desc: ('topic_desc' in action.payload) ? action.payload.topic_desc : state.topic_desc,
                isPrivate: ('isPrivate' in action.payload) ? action.payload.isPrivate : state.isPrivate,
                assigned_to: action.payload.assigned_to,
                searchUsers: action.payload.searchUsers
            };
        case 'SET_MODAL_ERROR':
            return {
                ...state,
                isFetching: false,
                textLebel: "Error Fetching data.Please Reload.",
                isSearchLoading: false
            };
        case 'SET_SEARCH_LOADING':
            return {
                ...state,
                isSearchLoading: action.payload
            };
        case 'SET_SEARCH_LOADING_WITH_TOPIC_DETAILS':
            return {
                ...state,
                textLebel: action.payload.textLebel,
                isSearchLoading: action.payload.isSearchLoading,
                topic_name: action.payload.topic_name,
                topic_desc: action.payload.topic_desc,
                isPrivate: action.payload.isPrivate
            }
        case 'SET_ASSIGNED_USERS':
            return {
                ...state,
                assigned_to: action.payload
            };
        case 'SET_FIELD_VALUE':
            return {
                ...state,
                [action.payload[0]]: action.payload[1]
            };
        case 'SET_SEARCH_LOADING_AND_SEARCH_USERS':
            return {
                ...state,
                isSearchLoading: action.payload.isSearchLoading,
                searchUsers: [...state.searchUsers, ...action.payload.searchUsers]
            };
        default:
            return initState;
    }
}