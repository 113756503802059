import { Container } from '@mui/material';
import CurrentAffairsSkeleten from './../components/currentAffairsSkeleten';
import LoadingContent from '../components/loadingContent';
import Box from '@mui/material/Box';
import axios from 'axios';

import React from 'react';
import ApiConfig from '../config/apiConfig';

import CurrentAffair from '../components/currentAffair';
import CurrentAffairsTopbar from '../components/currentAffairsTopbar';
import InfiniteScroll from "react-infinite-scroll-component";
import ModalDialog from '../components/modalDialog';
import TextEditorModal from '../components/textEditorModal';
import _ from 'lodash';
import Notify from '../components/notify';

function CurrentAffairs({ handleLogout }) {
    console.log("currentAffairs lodaed");
    const [rowId, setRowId] = React.useState(0);
    const [isEdit, setIsEdit] = React.useState(false);
    const [modalDialogOpen, setModalDialogOpen] = React.useState(false);
    const [onSuccess, setOnsuccess] = React.useState(false);
    const [ca_data, setCaData] = React.useState([]);
    const [errMsg, setErrorMsg] = React.useState("");
    const [hasMore, setHasMore] = React.useState(true);
    const [pageNo, setPageNo] = React.useState(1);

    const [Edit, setEdit] = React.useState({
        open: false,
        initData: '',
        heading: 'Update This Data',
        buttonName: 'Update'
    });
    const savetoState = (data) => {
        setErrorMsg('');
        setCaData([data, ...ca_data]);
    }
    const matchDivColorOnChangeTheme = () => {
        try {
            if (window.globalTheme) {
                window.document.querySelector('.MuiTypography-root > div').setAttribute('class', "darkModeColor");
            }
        } catch (err) { }
    }
    const fetchData = () => {
        console.log('fetching data...', pageNo, hasMore)
        if (hasMore) {

            console.log(pageNo)
            axios({
                method: 'post',
                url: ApiConfig.url + "/currentaffairs",
                headers: {
                    'Authorization': "Bearer " + window.localStorage.getItem("token")
                },
                data: {
                    pageNo: pageNo
                }
            }).then(function (response) {
                console.log(response)
                if (response.status === 200) {
                    if (response.data?.isLogout === true) {
                        handleLogout();
                    }
                    if (response.data.success === true && response.data.IsMode === true) {
                        setErrorMsg("");
                        setPageNo(pageNo + 1)
                        let data1 = response.data.data;
                        ca_data.length > 0 ? setCaData(prevItems => [...prevItems, ...data1]) : setCaData(data1);
                        matchDivColorOnChangeTheme();

                    } else if (response.data.success === true && response.data.IsMode === false) {
                        setHasMore(false);
                    }
                    try {
                        if (localStorage.getItem('theme') === 'dark') {
                            window.document.querySelectorAll(".MuiTypography-root>div").forEach((element) => {
                                element.classList.add("darkModeDiv");
                            })
                        }
                    } catch (err) { }
                }
                else {
                    setHasMore(false);
                    setCaData([]);
                    setErrorMsg(response.data.msg);
                }

            }).catch(err => {
                setCaData([]);
                setErrorMsg("Unexpected error occured. Failed to fetch." + err);
            })
        }

    }
    const handleChangeConfig = (isDate = false, fetchDate = '', isTextSearch = false, likeString = '', isAll = false) => {
        console.log(isTextSearch, likeString, isAll)
        let body = {
            fetchByDate: isDate,
            fetchDate: fetchDate !== '' ? new Date(fetchDate).toISOString().slice(0, 10) : '',
            fetchByKeyword: isTextSearch,
            likeString: likeString
        };
        if (isAll) {
            body = {
                pageNo: 1
            };
            setPageNo(1);
        }
        axios({
            method: 'post',
            url: ApiConfig.url + "/currentaffairs",
            headers: {
                'Authorization': "Bearer " + window.localStorage.getItem("token")
            },
            data: body
        }).then(function (response) {
            if (response.status === 200) {
                if (response.data?.isLogout === true) {
                    handleLogout();
                }
                if (response.data.success === true) {
                    setErrorMsg("");
                    setCaData(response.data.data);
                    matchDivColorOnChangeTheme();
                    if (isAll) {
                        setPageNo(pageNo + 1);
                        setHasMore(true);
                    } else {
                        setHasMore(false);
                    }
                    try {
                        if (localStorage.getItem('theme') === 'dark') {
                            window.document.querySelectorAll(".MuiTypography-root>div").forEach((element) => {
                                element.classList.add("darkModeDiv");
                            })
                        }
                    } catch (err) { }
                    setOnsuccess(!onSuccess);
                } else {
                    setHasMore(false);
                    setCaData([]);
                    setOnsuccess(!onSuccess);
                    setErrorMsg(response.data.msg);
                }
            }
        }).catch(err => {
            console.log(err);
            setCaData([]);
            setErrorMsg("Unexpected error occured. Failed to fetch." + err);
        })
    }

    const onDelete = (id) => {
        setRowId(id);
        setIsEdit(false);
        setModalDialogOpen(true);
    }
    const onEdit = (id) => {
        console.log(id)
        setRowId(id);
        setIsEdit(true);
        setEdit({ ...Edit, initData: ca_data.find(obj => obj.id === id).paragraph, open: true });
    }
    const cancelEdit = () => {
        setEdit({ ...Edit, open: false });
    }
    const handleUpdate = (updatedData) => {
        console.log(updatedData)
        axios({
            method: 'put',
            url: ApiConfig.url + "/currentaffairs/update/" + rowId,
            headers: {
                'Authorization': "Bearer " + window.localStorage.getItem("token")
            },
            data: {
                paragraph: String(updatedData.replace(/\n/g, ''))
            }
        }).then(function (response) {
            console.log(response);
            if (response.status === 200) {
                if (response.data?.isLogout === true) {
                    handleLogout();
                }
                if (response.data.success === true) {
                    console.log(response.data);
                    setEdit({ ...Edit, open: false });
                    Notify().success(response.data.msg);

                    let deepCa_data = _.cloneDeep(ca_data);
                    _.set(_.find(deepCa_data, { id: rowId }), 'paragraph', updatedData);
                    setCaData(deepCa_data);
                } else {
                    Notify().success(response.data.msg);
                }
            }
        }).catch(err => {
            console.log(err);
            Notify().error('Failed to Update.' + err);
        })
    }
    const handlePermit = (isPermitted) => {
        if (isPermitted) {
            axios({
                method: 'delete',
                url: ApiConfig.url + "/currentaffairs/delete/" + rowId,
                headers: {
                    'Authorization': "Bearer " + window.localStorage.getItem("token")
                },
            }).then(function (response) {
                console.log(response);
                if (response.status === 200) {
                    if (response.data?.isLogout === true) {
                        handleLogout();
                    }
                    if (response.data.success === true) {
                        Notify().success(response.data.msg);
                        let newArrayofObjs = ca_data.filter(item => item.id !== rowId);
                        if (newArrayofObjs.length > 0) {
                            setCaData(newArrayofObjs);
                        } else {
                            setCaData([]);
                            setErrorMsg(response.data.msg);
                        }
                    } else {
                        setErrorMsg(response.data.msg);
                        Notify().error(response.data.msg);
                    }
                }
            }).catch(err => {
                console.log(err);
            })
            // handleEditOrDelete(isEdit, rowId);
        } else {
            console.log(isPermitted, isEdit, 'delete', rowId);
        }
        setModalDialogOpen(false);
    }
    React.useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxWidth="lg">
            <CurrentAffairsTopbar handleLogout={handleLogout} handleChangeConfig={handleChangeConfig} onSuccess={onSuccess} savetoState={savetoState} />
            <Box>
                {
                    errMsg !== "" ?
                        <>
                            <Container maxWidth="lg" sx={{ mt: 3 }}>
                                {errMsg}
                            </Container>
                        </> : (errMsg === "" && ca_data.length > 0) ?

                            <>
                                <ModalDialog title='Really want to delete?' open={modalDialogOpen} children='It will delete full details from Current Affairs List' modalToParent={handlePermit} />

                                <TextEditorModal open={Edit.open} closeModal={cancelEdit} initData={Edit.initData} heading={Edit.heading} buttonName={Edit.buttonName} handleSubmit={handleUpdate} />

                                <InfiniteScroll
                                    dataLength={ca_data.length} //This is important field to render the next data
                                    next={fetchData}

                                    hasMore={hasMore}
                                    loader={<CurrentAffairsSkeleten total={3} />}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    scrollThreshold="150px"
                                >
                                    {
                                        ca_data.map(data => {
                                            return (<CurrentAffair key={data.id} cfData={data} onDelete={onDelete} onEdit={onEdit} />)

                                        })
                                    }
                                </InfiniteScroll>

                            </>
                            :
                            (
                                <>
                                    <LoadingContent loaderType={"circle"} color='secondary' />
                                </>
                            )
                }
            </Box>
        </Container >
    );
}

export default CurrentAffairs;