import { Box, Divider, Drawer, FormControlLabel, FormGroup, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import SecuritySharpIcon from '@mui/icons-material/SecuritySharp';
import PublicSharpIcon from '@mui/icons-material/PublicSharp';
import MaterialUISwitch from "../config/muiThemeSwitch";
import * as React from 'react';
import { GlobalContext } from "../contexts/globalContext";
import TutorialStudies from './../logo/tutorialstudies.png';
import { Link } from "react-router-dom";
function MobileMenu(props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { userdata } = React.useContext(GlobalContext);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
            >
                <MenuIcon />
            </IconButton>
            <Box
                component="nav"

                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{

                        keepMounted: true,
                        disablePortal: true // Better open performance on mobile.
                    }}
                >
                    <br />
                    <img height={70} src={TutorialStudies} alt="Tutorial Studies" style={{ backgroundColor: 'brown' }} />
                    <Divider>
                        <h3>Main Menu</h3>
                    </Divider>
                    <List>
                        {userdata.routes.map((nav, index) => (
                            nav[4] === 0 &&
                            <Link to={`${nav[1]}`} key={index}>
                                <ListItem button onClick={handleDrawerToggle}>

                                    <ListItemIcon >
                                        {nav[6] ? <SecuritySharpIcon /> : <PublicSharpIcon />}
                                    </ListItemIcon>

                                    <ListItemText primary={nav[0]} />

                                </ListItem>
                            </Link>
                        ))}
                    </List>
                    <Divider />
                    <FormGroup sx={{ pl: 2 }}>
                        <FormControlLabel
                            checked={(props.theme === "dark") ? true : false}
                            control={<MaterialUISwitch sx={{ m: 1 }} />}
                            label="Theme"

                            onChange={props.handleThemeChange}
                        />
                    </FormGroup>
                </Drawer>
            </Box>
        </>
    );
}

export default MobileMenu;