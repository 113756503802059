import React from 'react';

export const GlobalContext = React.createContext();

export const GlobalContextProvider = (props) => {
    console.log("GlobarContext Rendered");
    // eslint-disable-next-line no-unused-vars
    const [userdata, setUserData] = React.useState({
        user_id: null,
        user_name: "",
        user_email: "",
        full_name: "",
        datetime: "",
        login: true,
        totalMails: 0,
        routes: []
    });
    // const logout = () => {
    //     setUserData(prevData => ({ ...prevData, login: false, routes: [] }));
    // }
    const providerValue = React.useMemo(() => ({ userdata, setUserData }), [userdata, setUserData]);
    return (
        <GlobalContext.Provider
            value={providerValue}>
            {props.children}
        </GlobalContext.Provider>
    );
}