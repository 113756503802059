
//tab topics cache storage----------------------------------------
// const tabTopicsCache = {
//     'tab_id':'tab_topics'
// }

let tabTopicsCache = {};
const setTopicCache = (tab_id, data) => {
    tabTopicsCache[tab_id] = data;
    console.log(tabTopicsCache);
}
const getTopicCache = (tab_id) => {
    return tab_id in tabTopicsCache ? tabTopicsCache[tab_id] : undefined;
}
const setTopicArray = (tab_id, topicArray) => {
    tabTopicsCache[tab_id]['data'] = topicArray;
    // console.log(tab_id, topicArray);
    // console.log(tabTopicsCache[tab_id]['data']);
}


// topic details cache storage------------------------------------

let topicDetailsCache = {};
const setTopicDetails = (topic_id, data) => {
    topicDetailsCache[topic_id] = data;
}
const getTopicDetails = (topic_id) => {
    return topic_id in topicDetailsCache ? topicDetailsCache[topic_id] : undefined;
}

const setTopicDetailsArray = (topic_id, topicDetailsArray) => {
    topicDetailsCache[topic_id].data = topicDetailsArray;
}

const clearAll = () => {
    topicDetailsCache = {};
    tabTopicsCache = {};
}
export { clearAll, tabTopicsCache, setTopicCache, getTopicCache, setTopicArray, topicDetailsCache, setTopicDetails, getTopicDetails, setTopicDetailsArray };