import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import LoginForm from '../components/loginform';
import RegisterForm from '../components/registerform';
import { useLocation, useNavigate } from 'react-router-dom';
function Form(props) {
    const location = useLocation();
    const navigate = useNavigate();
    console.log("form rendered");
    const [value, setValue] = React.useState(props.tabActive);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // async function getusers(url) {
    //     const response = await fetch(url);
    //     var data = await response.json();
    //     console.log(data);
    // }
    React.useEffect(() => {
        setValue(props.tabActive);
        // getusers(ApiConfig.url + "api/users");
    }, [props.tabActive]);

    const redirect = () => {
        console.log("redirect")
        navigate("/", []);
    }
    return (
        <Card sx={{ width: '400px' }}>
            <CardContent>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Login" value="1" />
                            <Tab label="Register" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" >
                        <LoginForm location={location} redirect={redirect} />
                    </TabPanel>
                    <TabPanel value="2">
                        <RegisterForm />
                    </TabPanel>
                </TabContext>
            </CardContent>
        </Card>

    );
}

export default Form;