import Editor from "@monaco-editor/react";
import { Container, Stack, TextField } from "@mui/material";
import React from "react";
import Config from '../config/config';
import MenuItem from '@mui/material/MenuItem';
import SampleCodes from "../components/sampleCodes";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
function CodeEditor() {
    const [langId, setLangId] = React.useState(1);
    const [apiMode, setApiMode] = React.useState(Config.apiModes[0]);
    const [defTheme, setDefTheme] = React.useState(localStorage.getItem('theme') === 'dark' ? Config.defaultThemes[1] : Config.defaultThemes[0]);
    const [editorValue, setEditorValue] = React.useState(SampleCodes[1]);
    const [saveCode, setSaveButton] = React.useState(false);
    const [compileCode, setCompileButton] = React.useState(false);
    function handleEditorChange(value, event) {
        // here is the current value
    }

    function handleEditorDidMount(editor, monaco) {
        // console.log("onMount: the editor instance:", editor);
        // console.log("onMount: the monaco instance:", monaco)
    }

    function handleEditorWillMount(monaco) {
        // console.log("beforeMount: the monaco instance:", monaco);
    }

    function handleEditorValidation(markers) {
        // model markers
        // markers.forEach(marker => console.log('onValidate:', marker.message));
    }
    const handleLanguageChange = (e) => {
        let id = e.target.value;
        setLangId(id);
        setEditorValue(SampleCodes[id]);

    }
    const handleThemeChange = (e) => {
        setDefTheme(e.target.value);
    }

    const handleApiChange = (e) => {
        setApiMode(e.target.value);
    }




    const HandleSaveCode = () => {
        setSaveButton(true);
    }
    const HandlecompileCode = () => {
        setCompileButton(true);
    }

    React.useEffect(() => {
        localStorage.getItem('theme') === 'dark' ? setDefTheme(Config.defaultThemes[1]) : setDefTheme(Config.defaultThemes[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem('theme')]);
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={6} md={2}>
                    <TextField
                        size="small"
                        fullWidth
                        select
                        value={langId}
                        label="Select Language"
                        onChange={handleLanguageChange}
                    >
                        {Config.supportedLanguages.map(language => (
                            <MenuItem key={language.id} value={language.id} >
                                {language.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={6} md={2}>
                    <TextField
                        size="small"
                        fullWidth
                        select
                        value={defTheme}
                        label="Select Theme"
                        onChange={handleThemeChange}
                    >
                        {Config.defaultThemes.map(theme => (
                            <MenuItem key={theme} value={theme} >
                                {theme}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6} md={2}>
                    <TextField
                        size="small"
                        fullWidth
                        select
                        value={apiMode}
                        label="Select Api Mode"
                        onChange={handleApiChange}
                    >
                        {Config.apiModes.map(mode => (
                            <MenuItem key={mode} value={mode} >
                                {mode}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>

            <Divider sx={{ mb: 3, mt: 2 }} />
            <Card variant="outlined" sx={{ background: "#e0e0e0", mb: 2 }}>
                <Editor
                    height="70vh"
                    theme={defTheme}
                    defaultLanguage="apex"
                    language={Config.supportedLanguages.find(obj => obj.id === langId).name}
                    defaultValue={editorValue}
                    value={editorValue}
                    onChange={handleEditorChange}
                    onMount={handleEditorDidMount}
                    beforeMount={handleEditorWillMount}
                    onValidate={handleEditorValidation}
                />
            </Card>
            <Box>
                <Stack justifyContent="flex-end"
                    alignItems="flex-end" direction="row" spacing={2} >
                    <LoadingButton size="small" variant="contained" loading={compileCode} color="secondary" onClick={HandlecompileCode}>
                        Compile
                    </LoadingButton>
                    <LoadingButton size="small" variant="text" loadingIndicator="Saving..." loading={saveCode} color="success" onClick={HandleSaveCode}>
                        Save to your Draft
                    </LoadingButton>
                </Stack>
            </Box>
        </Container>
    );
}

export default CodeEditor;