import axios from "axios";
import ApiConfig from "../../config/apiConfig";
import React from 'react';
import { GlobalContext } from './../../contexts/globalContext';
import MuiDataTable from "../MuiDataTable";
import ModalDialog from "../modalDialog";
import { Alert, Button, Snackbar, Stack } from "@mui/material";
import TabEditModal from "../tabEditModal";
import AddBoxIcon from '@mui/icons-material/AddBox';

function CustomTabSettings() {
    const { userdata } = React.useContext(GlobalContext);
    const [tabData, setTabData] = React.useState([]);
    const [tabSchemaData, setTabSchemaData] = React.useState([]);
    const [showCreateOrEditModal, setShowCreateOrEditModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [editMode, setEditMode] = React.useState(true);
    const [alertData, setAlertData] = React.useState({
        isSuccess: false,
        msg: ''
    });
    const [editOrCreateTabData, setCreateOrEditTabData] = React.useState({
        isPrivate: 0,
        isShown: 0,
        tab_name: '',
        tab_link: '/',
        assigned_to: [],
    });
    const [createOrEditModalData, setCreateOrEditModalData] = React.useState({
        title: `Edit ${editOrCreateTabData?.tab_name} Tab`,
        buttonName: 'Update'
    })
    const [deleteTabData, setDeleteTabData] = React.useState([]);
    const handleEdit = (arr) => {
        setEditMode(true);
        setCreateOrEditTabData(tabData.find(obj => obj.id === arr[0]));
        setShowCreateOrEditModal(true);
    }
    const handleCreate = () => {
        setEditMode(false);
        setCreateOrEditModalData({
            title: `Create New Tab`,
            buttonName: 'Create'
        })
        let obj = {
            isPrivate: 0,
            isShown: 0,
            tab_name: '',
            tab_link: '/',
            assigned_to: [],
        }
        setCreateOrEditTabData({
            ...obj
        });
        setShowCreateOrEditModal(true);
    }
    const handleDelete = (arr) => {
        setDeleteTabData(arr);
        setShowDeleteModal(true);
    }
    const handlecreateOrEditPermitted = (editLogic) => {
        if (!editLogic) {
            setShowCreateOrEditModal(false);
        }
    }
    const handleModalDeletePermit = (isPermitted) => {
        setShowDeleteModal(false);
        if (isPermitted) {
            axios({
                method: 'delete',
                url: ApiConfig.url + "/custompage/delete",
                headers: {
                    'Authorization': "Bearer " + window.localStorage.getItem("token")
                },
                data: {
                    user_id: userdata.user_id,
                    delete_arr: deleteTabData
                }
            }).then(function (response) {
                if (response.status === 200) {
                    console.log(response)
                    if (response.data.success === true) {
                        const newTabData = tabData.filter(item => !deleteTabData.includes(item.id));
                        setTabData(newTabData);
                        setAlertData({ isSuccess: true, msg: response.data.msg });
                    } else {
                        setAlertData({ isSuccess: false, msg: response.data.msg });
                    }
                } else {
                    setAlertData({ isSuccess: false, msg: response.data.msg });
                }
            }).catch(err => {
                console.log("Unexpected Error Occured:", err);
            })
        }
    }
    const closeAlert = () => {
        window.setTimeout(() => { setAlertData({ isSuccess: false, msg: '' }); }, 3000);
    }
    const handleCreateOrUpdateData = (newdata = {}) => {
        if (editMode) {
            handleUpdateData(newdata);
            setShowCreateOrEditModal(false);
        } else {
            handleCreateData(newdata);
        }

    }
    const handleCreateData = (addData) => {

        console.log('insert function called');
        axios({
            method: 'post',
            url: ApiConfig.url + "/custompage/add",
            headers: {
                'Authorization': "Bearer " + window.localStorage.getItem("token")
            },
            data: {
                user_id: userdata.user_id,
                isPrivate: addData.isPrivate,
                isShown: addData.isShown,
                tab_name: addData.tab_name,
                tab_link: addData.tab_link,
                assigned_to: addData.assigned_to
            }
        }).then(function (response) {
            if (response.status === 200) {
                console.log(response)
                if (response.data.success === true) {
                    console.log("insert success");
                    setShowCreateOrEditModal(false);
                    const newTabData = {
                        id: response.data.last_id,
                        tab_name: addData.tab_name,
                        tab_link: addData.tab_link,
                        isPrivate: addData.isPrivate,
                        isShown: addData.isShown,
                        assigned_to: addData.assigned_to,
                        datetime: response.data.datetime
                    };
                    setTabData([...tabData, newTabData]);
                    setAlertData({ isSuccess: true, msg: response.data.msg });
                } else {
                    setAlertData({ isSuccess: false, msg: response.data.msg });
                    console.log("Insert failed");
                }
            } else {
                setAlertData({ isSuccess: false, msg: response.data.msg });
                console.log("insert failed");
            }
            closeAlert();
        }).catch(err => {
            console.log("Unexpected Error Occured:", err);
            closeAlert();
        })
    }
    const handleUpdateData = (updateData) => {
        // removal_array: editOrCreateTabData.assigned_to.filter(f => !updateData.assigned_to.includes(f))
        console.log('update function called');
        axios({
            method: 'put',
            url: ApiConfig.url + "/custompage/update/" + editOrCreateTabData.id,
            headers: {
                'Authorization': "Bearer " + window.localStorage.getItem("token")
            },
            data: {
                user_id: userdata.user_id,
                isPrivate: updateData.isPrivate,
                isShown: updateData.isShown,
                tab_name: updateData.tab_name,
                tab_link: updateData.tab_link,
                assigned_to: updateData.assigned_to,
            }
        }).then(function (response) {
            if (response.status === 200) {
                console.log(response)
                if (response.data.success === true) {
                    console.log("Update success");
                    const newTabData = tabData.map(x => (x.id === updateData.id ? {
                        ...x, isPrivate: updateData.isPrivate,
                        isShown: updateData.isShown,
                        tab_name: updateData.tab_name,
                        tab_link: updateData.tab_link,
                        assigned_to: updateData.assigned_to
                    } : x));
                    setTabData(newTabData);
                    setAlertData({ isSuccess: true, msg: response.data.msg });
                } else {
                    setAlertData({ isSuccess: false, msg: response.data.msg });
                    console.log("Update failed");
                }
            } else {
                setAlertData({ isSuccess: false, msg: response.data.msg });
                console.log("Update failed");
            }
            closeAlert();
        }).catch(err => {
            console.log("Unexpected Error Occured:", err);
            closeAlert();
        })
    }
    const getTabs = () => {
        axios({
            method: 'get',
            url: ApiConfig.url + "/user/getCustomTabs/" + userdata.user_id,
            headers: {
                'Authorization': "Bearer " + window.localStorage.getItem("token")
            }
        }).then(function (response) {
            if (response.status === 200) {
                console.log(response)
                if (response.data.success === true) {
                    setTabData(response.data.tabs);
                    setTabSchemaData(response.data.tabsSchema);
                } else {
                    setTabData([]);
                    setTabSchemaData([]);
                }
            } else {
                setTabData([]);
                setTabSchemaData([]);
                console.log('failed to load')
            }
        }).catch(err => {
            console.log("Unexpected Error Occured:", err);
        })
    }
    React.useEffect(() => {
        getTabs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <ModalDialog title='Really want to delete this topic?' open={showDeleteModal} children='It will delete this topic and related topic details.' modalToParent={handleModalDeletePermit} />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={alertData.msg !== ''}>
                <Alert severity={alertData.isSuccess ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {alertData.msg}
                </Alert>
            </Snackbar>
            <Stack justifyContent="flex-end"
                alignItems="flex-end" direction="row" spacing={2} sx={{ mb: 2 }}>
                <Button color="secondary" variant="contained" size="small" startIcon={<AddBoxIcon />} onClick={handleCreate}>
                    Add New Tab
                </Button>
            </Stack>

            <TabEditModal title={createOrEditModalData.title} tabData={editOrCreateTabData} modalopen={showCreateOrEditModal} setModalOpenParent={handlecreateOrEditPermitted} saveOrUpdateButtonName={createOrEditModalData.buttonName} savedataToParent={handleCreateOrUpdateData} editMode={editMode} />


            <MuiDataTable Rows={tabData} Order={'asc'} OrderBy={'id'} RowsPerPage={5} MetaData={tabSchemaData} IsDense={false} HandleEdit={handleEdit} HandleDelete={handleDelete} />

        </>
    );
}

export default CustomTabSettings;