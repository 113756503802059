import { CircularProgress, Grid, LinearProgress } from '@mui/material';
function LoadingContent(props) {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: props.minHeight }}
        >

            {
                props.loaderType === "circle" ? (
                    <Grid sx={{ mt: 5 }}>
                        <CircularProgress color={props.color ? props.color : "primary"} />
                    </Grid>
                ) : props.loaderType === "linear" ? (
                    <Grid item width="120px">
                        <LinearProgress color={props.color ? props.color : "primary"} />
                    </Grid>
                ) : (
                    <span>Loading...</span>
                )
            }

        </Grid>);
}

export default LoadingContent;