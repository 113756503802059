import { Card, Container, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ModalDialog from '../components/modalDialog';
import TextEditor from '../components/texteditor';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import ApiConfig from '../config/apiConfig';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import renderHTML from 'react-render-html';
import TopicDetailsSkeleten from '../components/topicDetailsSkeleten.';
function TopicDetails() {
    const parameters = useParams();
    const loc = useLocation();
    const [open, setOpen] = React.useState(false);
    const [topic_data, setTopic_data] = React.useState([]);
    const [errMsg, setErrorMsg] = React.useState("");
    console.log("TopicDetails rendered")

    const handleClickOpen = () => {
        setOpen(true);
    };

    const modalToParent = (data) => {
        setOpen(data);
        // console.log("called")
    }

    React.useEffect(() => {
        axios({
            method: 'get',
            url: ApiConfig.url + "/topics/" + parameters.topicId
        }).then(function (response) {
            if (response.status === 200 && response.data.success === true) {
                setErrorMsg("");
                setTopic_data(response.data.data);
                console.log(response.data.data);
                try {
                    window.hljs.highlightAll();
                    window.hljs.configure({ ignoreUnescapedHTML: true });
                } catch (err) { }
                try {
                    if (window.globalTheme) {
                        window.document.querySelector('.MuiTypography-root > div').setAttribute('class', "darkModeColor");
                    }
                } catch (err) { }
            } else {
                setErrorMsg(response.data.msg);
            }
        }).catch(err => {
            console.log(err);
            setErrorMsg("Unexpected error occured. Failed to fetch.")
        })

    }, [parameters.topicId]);
    return (
        <Container>
            <Box >
                <Box sx={{ textAlign: "center" }}>
                    <h2>
                        {loc.state.topic_name}
                    </h2>

                    <h4>
                        {loc.state.topic_desc}
                    </h4>
                </Box>
                <Divider light />
                <Stack justifyContent="flex-end"
                    alignItems="flex-end" direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Button color="secondary" variant="outlined" size="small" startIcon={<AddBoxIcon />} onClick={handleClickOpen}>
                        Add New passage
                    </Button>
                </Stack>

                <ModalDialog title="Create New passage to this Topic" fullscreen open={open} modalToParent={modalToParent}>
                    <TextEditor initData="this is sample data" />
                </ModalDialog>
            </Box>
            <Box>
                <>
                    {
                        errMsg !== "" ?
                            <>
                                <Container maxWidth="lg">
                                    {errMsg}
                                </Container>
                            </> : (errMsg === "" && topic_data.length > 0) ?
                                <>
                                    {
                                        topic_data.map((data) => (
                                            <Card variant="outlined" sx={{ mt: 1 }} key={data.id}>
                                                <CardContent>
                                                    <Typography align="right" sx={{ fontSize: 13 }} color="text.secondary" gutterBottom>
                                                        Last modified on {new Date(data.datetime).toLocaleString('en-GB', { hour12: true })}
                                                    </Typography>
                                                    <Divider sx={{ mb: 1 }} />
                                                    <Typography variant="body1" align="justify" component="div">
                                                        {renderHTML(data.topic_details)}
                                                    </Typography>

                                                </CardContent>
                                                <CardActions disableSpacing sx={{
                                                    mx: 'auto',
                                                    justifyContent: "flex-end"
                                                }}>

                                                    <Tooltip title="Edit">
                                                        <IconButton color="secondary" size="small" ><EditIcon /></IconButton>
                                                    </Tooltip>

                                                    <Tooltip title="Delete">
                                                        <IconButton color="error" size="small"><DeleteIcon /></IconButton>
                                                    </Tooltip>
                                                </CardActions>

                                            </Card>
                                        ))
                                    }

                                </> :
                                (
                                    <TopicDetailsSkeleten total={3} />
                                    // <LoadingContent loaderType="circle" color="secondary" minHeight="200px" />
                                )
                    }
                </>











                {/* {
                    topic_data.length === 0 &&
                    <LoadingContent loaderType="linear" minHeight="200px" />
                }
                {
                    topic_data.map((data) => (
                        <Card variant="" sx={{ p: 1, mt: 1 }} key={data.id}>
                            <CardContent>
                                <Typography align="right" sx={{ fontSize: 13 }} color="text.secondary" gutterBottom>
                                    Last edited by Swapnamoy Hazra
                                </Typography>
                                <Divider sx={{ mb: 1 }} />
                                <Typography variant="body1" align="justify" component="div">
                                    {data.topicDetails}
                                </Typography>

                            </CardContent>
                            <CardActions disableSpacing sx={{
                                mx: 'auto',
                                justifyContent: "flex-end"
                            }}>
                                <Tooltip title="Edit">
                                    <IconButton color="secondary" size="small"><EditIcon /></IconButton>
                                </Tooltip>

                                <Tooltip title="Delete">
                                    <IconButton color="error" size="small"><DeleteIcon /></IconButton>
                                </Tooltip>
                            </CardActions>

                        </Card>
                    ))
                } */}

            </Box>

        </Container>
    );
}

export default TopicDetails;