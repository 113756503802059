import { IconButton, Stack, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import AddBoxIcon from '@mui/icons-material/AddBox';
import React from 'react';
import TextEditorModal from './textEditorModal';
import axios from 'axios';
import ApiConfig from '../config/apiConfig';
import { GlobalContext } from '../contexts/globalContext';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomTabListDetailsSettingsBar from './customTabListDetailsSettingsBar';
import { useLocation } from 'react-router-dom';
import Notify from './notify';
function CustomTabListDetailsTopBar({ onSuccess, handleChangeConfig, savetoState, tabId }) {
    const { userdata } = React.useContext(GlobalContext);
    const loc = useLocation();
    const [createOrEdit, setCreateOrEdit] = React.useState({
        saveDate: '',
        open: false,
        initData: '',
        heading: 'Upload New Passage',
        buttonName: 'Create',
        toggle: false,
    });
    console.log("CustomTabListDetailsTopBar rendered")
    const [showSettingsBar, setSettingsBar] = React.useState(false);

    const handleClickOpen = () => {
        setCreateOrEdit({ ...createOrEdit, open: true, initData: '', toggle: !createOrEdit.toggle });
    };

    const handleClose = () => {
        setCreateOrEdit({ ...createOrEdit, open: false, toggle: !createOrEdit.toggle });
    };

    const handleSubmit = (data) => {
        axios({
            method: 'post',
            url: ApiConfig.url + "/custompage" + loc.pathname + '/add',
            data: {
                user_id: userdata.user_id,
                isPrivate: 1,
                assigned_to: null,
                tab_id: tabId,
                paragraph: String(data.replace(/\n/g, ''))
            }
        }).then(function (response) {
            if (response.status === 201) {
                console.log(response)
                let newObj = {
                    assigned_to: response.data.assigned_to,
                    datetime: response.data.datetime,
                    id: response.data.id,
                    isPrivate: response.data.isPrivate,
                    topic_details: data,
                    topic_id: loc.pathname.split('/')[2],
                    user_id: response.data.user_id
                }
                savetoState(newObj);
                Notify().success(response.data.msg);
                setCreateOrEdit({ ...createOrEdit, toggle: !createOrEdit.toggle });
            } else {
                console.log(response)
                Notify().error(response.data.msg);
                setCreateOrEdit({ ...createOrEdit, toggle: !createOrEdit.toggle });
            }
        }).catch(err => {
            console.log(err)
            Notify().error('Unexpected Error Occured.' + err);
        })
    }

    const handleSettings = () => {
        setSettingsBar(!showSettingsBar);
    }
    return (
        <>
            <Box>
                <Stack justifyContent="flex-end"
                    alignItems="flex-end" direction="row" spacing={2} >
                    <Tooltip title="Toggle Settings">
                        <IconButton color="secondary" onClick={handleSettings} aria-label="add an alarm">
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add new passage">
                        <IconButton color='info' onClick={handleClickOpen}>
                            <AddBoxIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>

            <div>
                <TextEditorModal open={createOrEdit.open} closeModal={handleClose} initData={createOrEdit.initData} heading={createOrEdit.heading} buttonName={createOrEdit.buttonName} handleSubmit={handleSubmit} toggle={createOrEdit.toggle} />
            </ div>


            {showSettingsBar &&
                <CustomTabListDetailsSettingsBar onSuccess={onSuccess} handleChangeConfig={handleChangeConfig} />
            }
        </>
    );
}

export default CustomTabListDetailsTopBar;