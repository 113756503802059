
import React from 'react';
import { Alert, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ApiConfig from '../config/apiConfig';
import Box from '@mui/material/Box';
const axios = require('axios');


export default class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        if (!ValidatorForm.hasValidationRule('isPasswordMatch')) {
            ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
                const { formData } = this.state;
                if (value !== formData.user_pass) {
                    return false;
                }
                return true;
            });
        }
    }
    state = {
        formData: {
            user_name: '',
            user_pass: '',
            user_repeat_pass: '',
            user_email: '',
            full_name: '',
            showPassword: false,
        },
        isLoading: false,
        messageText: "",
        success: false
    }

    handleChange = (event) => {
        const { formData } = this.state;
        formData[event.target.name] = event.target.value;
        if (event.target.name === 'user_pass') {
            this.form.isFormValid(false);
        }
        this.setState({ formData });
    }

    handleSubmit = () => {

        this.setState({ isLoading: true }, () => {
            // try {
            let globalState = this;
            axios({
                method: 'post',
                url: ApiConfig.url + "/user/add",
                data: this.state.formData
            }).then(function (response) {
                if (response.status === 201) {
                    globalState.setState({ isLoading: false, success: true, messageText: response.data.msg });
                } else if (response.status === 200) {
                    globalState.setState({ isLoading: false, success: false, messageText: response.data.msg });
                }
            }).catch(err => {
                console.log(err);
                globalState.setState({ isLoading: false, success: false });
            })
            // } catch (err) {
            //     console.error("Unexpected Error Occured :", err);
            // }
        });

    }
    handleClickShowPassword = () => {
        const { formData } = this.state;
        formData['showPassword'] = !formData['showPassword'];
        this.setState({ formData });
    }

    componentWillUnmount() {
        if (ValidatorForm.hasValidationRule('isPasswordMatch')) {
            ValidatorForm.removeValidationRule('isPasswordMatch');
        }
    }

    render() {
        const { formData, isLoading, messageText, success } = this.state;
        return (
            <ValidatorForm
                ref={form => (this.form = form)}
                onSubmit={this.handleSubmit}
            >
                <TextValidator
                    fullWidth
                    variant="standard"
                    label="Full Name"
                    onChange={this.handleChange}
                    name="full_name"
                    value={formData.full_name}
                    validators={['required']}
                    errorMessages={['Your Name is required']}

                />
                <br />
                <TextValidator
                    fullWidth
                    variant="standard"
                    label="Create Username"
                    onChange={this.handleChange}
                    name="user_name"
                    value={formData.user_name}
                    validators={['required']}
                    errorMessages={['Username is required']}

                />
                <br />
                <TextValidator
                    fullWidth
                    variant="standard"
                    label="Enter your Email"
                    onChange={this.handleChange}
                    name="user_email"
                    value={formData.user_email}
                    validators={['required', 'isEmail']}
                    errorMessages={['Email is required', "Enter a valid Email with .com"]}

                />
                <br />
                <TextValidator
                    fullWidth
                    disabled={(formData.user_email.includes(".com") && formData.user_email !== "") ? false : true}
                    label="Create new Password"
                    variant="standard"
                    onChange={this.handleChange}
                    name="user_pass"
                    value={formData.user_pass}
                    type="password" // <-- This is where the magic happens
                    validators={['required']}
                    errorMessages={['Password is required']}
                >
                </TextValidator>

                <br />
                <TextValidator
                    fullWidth
                    disabled={(formData.user_email.includes(".com") && formData.user_email !== "") ? false : true}
                    label="Repeat Password"
                    variant="standard"
                    onChange={this.handleChange}
                    name="user_repeat_pass"
                    value={formData.user_repeat_pass}
                    type={formData.showPassword ? "text" : "password"} // <-- This is where the magic happens
                    InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                >
                                    {formData.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    validators={['isPasswordMatch', 'required']}
                    errorMessages={['password mismatch', 'Repeat password is required']}
                >
                </TextValidator>
                <br />

                <LoadingButton
                    type="submit"
                    fullWidth
                    color="secondary"
                    loading={isLoading}
                    loadingIndicator="Checking..."
                    variant="outlined"
                >
                    Register
                </LoadingButton>
                <Box sx={{ mt: 2 }}>
                    {
                        success === true ? (
                            <Alert severity="success">
                                {messageText}
                            </Alert>
                        ) : success === false && messageText !== "" ? (
                            <Alert severity="error">
                                {messageText}
                            </Alert>
                        ) : (
                            <> </>
                        )
                    }
                </Box>
            </ValidatorForm>
        );
    }
}