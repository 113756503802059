import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import React from 'react';
function ModalDialog({ fullscreen, title, open, children, modalToParent }) {


    return (
        <Dialog
            disableEnforceFocus
            fullScreen={fullscreen ? true : false}
            open={open}
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="info" onClick={() => modalToParent(false)}>
                    Cancel
                </Button>
                <Button variant="contained" color="error" onClick={() => modalToParent(true)}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ModalDialog;