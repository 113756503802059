import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddBoxIcon from '@mui/icons-material/AddBox';
import TuneIcon from '@mui/icons-material/Tune';
import React from 'react';
import CurrentAffairsSettingsBar from './currentAffairsSettingsBar';
import TextEditorModal from './textEditorModal';
import axios from 'axios';
import ApiConfig from '../config/apiConfig';
import { GlobalContext } from '../contexts/globalContext';
import Notify from './notify';


function CurrentAffairsTopbar({ handleLogout, onSuccess, handleChangeConfig, savetoState }) {
    const { userdata } = React.useContext(GlobalContext);
    const [createOrEdit, setCreateOrEdit] = React.useState({
        saveDate: '',
        open: false,
        initData: '',
        heading: 'Upload New Current Affair',
        buttonName: 'Create',
        toggle: false,
    });

    const handleChangeConfig1 = (...params) => {
        if (params[0]) {
            setCreateOrEdit({ ...createOrEdit, saveDate: new Date(params[1]).toISOString().slice(0, 10) });
        } else {
            setCreateOrEdit({ ...createOrEdit, saveDate: '' });
        }

        handleChangeConfig(...params);
    }
    console.log("currentaffairsTopbar rendered")
    const [showSettingsBar, setSettingsBar] = React.useState(false);

    const handleClickOpen = () => {
        setCreateOrEdit({ ...createOrEdit, open: true, initData: '', toggle: !createOrEdit.toggle });
    };

    const handleClose = () => {
        setCreateOrEdit({ ...createOrEdit, open: false, toggle: !createOrEdit.toggle });
    };

    const handleSubmit = (data) => {
        console.log('data received: ' + data)
        axios({
            method: 'post',
            url: ApiConfig.url + "/currentaffairs/add",
            headers: {
                'Authorization': "Bearer " + window.localStorage.getItem("token")
            },
            data: {
                saveDate: createOrEdit.saveDate === '' ? new Date().toISOString().slice(0, 10) : createOrEdit.saveDate,
                user_id: userdata.user_id,
                paragraph: String(data.replace(/\n/g, ''))
            }
        }).then(function (response) {
            if (response.status === 200 && response.data?.isLogout === true) {
                handleLogout();
            }
            else if (response.status === 200 && response.data.success === true) {
                console.log(response)
                let newObj = {
                    id: response.data.id,
                    paragraph: data,
                    admin_id: response.data.admin_id,
                    datetime: response.data.datetime
                }
                savetoState(newObj);
                Notify().success(response.data.msg);
                setCreateOrEdit({ ...createOrEdit, toggle: !createOrEdit.toggle });
            } else {
                console.log(response);
                Notify().error(response.data.msg);
                setCreateOrEdit({ ...createOrEdit, toggle: !createOrEdit.toggle });
            }
        }).catch(err => {
            console.log(err)
            Notify().error('Unexpected Error Occured.' + err);
        })
    }

    const handleSettings = () => {
        setSettingsBar(!showSettingsBar);
    }
    return (
        <>
            <Box>
                <Stack justifyContent="flex-end"
                    alignItems="flex-end" direction="row" spacing={2} >
                    <Button variant="contained" startIcon={<TuneIcon />} onClick={handleSettings}>
                        {showSettingsBar ? 'Hide Settings' : 'Show Settings'}
                    </Button>
                    <Button variant="outlined" startIcon={<AddBoxIcon />} onClick={handleClickOpen}>
                        Add New
                    </Button>
                </Stack>
            </Box>

            <div>
                <TextEditorModal open={createOrEdit.open} closeModal={handleClose} initData={createOrEdit.initData} heading={createOrEdit.heading} buttonName={createOrEdit.buttonName} handleSubmit={handleSubmit} success={createOrEdit.toggle} />
            </ div>


            {showSettingsBar &&
                <CurrentAffairsSettingsBar onSuccess={onSuccess} handleChangeConfig={handleChangeConfig1} />
            }
        </>
    );
}

export default CurrentAffairsTopbar;