import React from 'react'
import { CKEditor } from 'ckeditor4-react';

class TextEditor extends React.Component {
    state = {
        editorData: ""
    };

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }
    handlechange = (event) => {
        this.setState({ editorData: event.editor.getData() });
    }
    render() {
        console.log("texteditor rendered");
        return (<>
            <CKEditor name='ckeditor' editorUrl="https://cdn.ckeditor.com/4.14.0/full-all/ckeditor.js"
                initData={this.props.initData} onChange={this.handlechange} />
        </>);
    }
}

export default TextEditor;


