import React from 'react';
import ModalForm from './modalform';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddBoxIcon from '@mui/icons-material/AddBox';

function TopicsTopbar({ createNewTopic_p, user_id }) {
    const [open, setOpen] = React.useState(false);
    const [saveButtonName, setSaveButtonName] = React.useState(false);

    console.log("TopicsTopbar rendered");

    const handleClickOpen = () => {
        setSaveButtonName("Save");
        setOpen(true);
    };
    const handleChangeModalOpen = (data) => {
        setOpen(data);
    }
    const handleSaveData = (topic_data) => {
        setOpen(false);
        createNewTopic_p(topic_data);
    }
    return (
        <>
            <Box>
                <Stack justifyContent="flex-end"
                    alignItems="flex-end" direction="row" spacing={2} >
                    <Button variant="outlined" startIcon={<AddBoxIcon />} onClick={handleClickOpen}>
                        Add New Topic
                    </Button>
                </Stack>
            </Box>
            <ModalForm user_id={user_id} modalopen={open} title="Create New Topic" topic_name_p="" topic_desc_p="" setModalOpenParent={handleChangeModalOpen} savedataToParent={handleSaveData} saveOrUpdateButtonName={saveButtonName} />
        </>
    );
}

export default React.memo(TopicsTopbar);