/* eslint-disable no-unused-vars */
import Grid from '@mui/material/Grid';
import Topic from '../components/topic';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import TopicsTopbar from '../components/topicsTopbar';
import React from 'react';
import ApiConfig from '../config/apiConfig';
import axios from 'axios';
import LoadingContent from '../components/loadingContent';
import ModalForm from './../components/modalform';
import AlertModal from './../components/alertModal';
import TopicsSkeleten from '../components/topicsSkeleten';
import { GlobalContext } from './../contexts/globalContext';
export default function Topics(props) {
    console.log("topics rendered")
    const [topics, setTopics] = React.useState([]);
    const [errMsg, setErrorMsg] = React.useState("");
    const [createResponse, setCreateResponse] = React.useState({});
    const { userdata } = React.useContext(GlobalContext);
    React.useEffect(() => {
        console.log("useeffect called");
        if (!localStorage.getItem("topics_data")) {
            console.log("Axios called");
            axios({
                method: 'post',
                url: ApiConfig.url + "/topics",
                data: {
                    user_id: userdata.user_id,
                    tab_id: props.data[5]
                }
            }).then(function (response) {
                console.log(response);
                if (response.status === 200 && response.data.success === true) {
                    console.log(response);
                    setTopics(response.data.data);
                    localStorage.setItem("topics_data", JSON.stringify(response.data.data));
                    setErrorMsg("");
                } else {
                    setErrorMsg("Failed to fetch, Please try again later.");
                }
            }).catch(err => {
                console.log(err);
                setErrorMsg("Unexpected error occured. Failed to fetch.")
            })
        } else {
            setTopics(JSON.parse(localStorage.getItem("topics_data")));
            setErrorMsg("");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNewTopicCreate = (topic_data_p) => {
        axios({
            method: 'post',
            url: ApiConfig.url + "/topic/add",
            data: {
                ...topic_data_p,
                tab_id: props.data[5],
                user_id: userdata.user_id,
                assigned_to: null,
                isPrivate: 0
            }
        }).then(function (response) {
            console.log(response);
            if (response.status === 201 && response.data.success === true) {
                setCreateResponse(response.data);
                let data = { id: response.data.last_id, user_id: userdata.user_id, topic_name: topic_data_p.topic_name, topic_desc: topic_data_p.topic_desc, isPrivate: 0, datetime: response.data.datetime };
                let stateData = [...topics];
                stateData.push(data);
                setTopics(stateData);

            } else if (response.status === 200 && response.data.success === false) {
                setCreateResponse(response.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <>
            {
                errMsg !== "" ?
                    <>
                        <Container >
                            {errMsg}
                        </Container>
                    </> : (errMsg === "" && topics.length > 0) ?
                        (<>
                            {/* <ModalForm modalopen={editmodalOpen} title={`Edit ${data.topic_name}`} topic_name_p={data.topic_name} topic_desc_p={data.topic_desc} setModalOpenParent={handleModalAction} savedataToParent={handleSaveData} saveOrUpdateButtonName="Update" response={response_p} />
                            <AlertModal isOpen={deletemodalOpen} handleAllowParent={handleModalAction} allowButtonText={modalConfig.allowButtonText} cancelButtonText={modalConfig.cancelButtonText} modalHeading={modalConfig.modalHeading} modalDesc={modalConfig.modalDesc} /> */}

                            <Container>
                                <Box>
                                    <TopicsTopbar createNewTopic_p={handleNewTopicCreate} response_p={createResponse} />
                                </Box>
                                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    {topics.map((data, index) => (
                                        <Grid container item xs={12} sm={12} md={4} key={index}>
                                            <Topic data={data} link='/topics' />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Container>
                        </>) :
                        (
                            <TopicsSkeleten total={9} />
                        )

            }
        </>
    );
}
