/* eslint-disable no-unused-vars */
import { Alert, Badge, Card, Container, Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import ApiConfig from '../config/apiConfig';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import renderHTML from 'react-render-html';
// import TopicDetailsSkeleten from '../components/topicDetailsSkeleten.';
import { GlobalContext } from '../contexts/globalContext';
import CustomTabListDetailsTopBar from '../components/customTabListDetailsTopBar';
import ModalDialog from './../components/modalDialog';
import _ from 'lodash';
import TextEditorModal from '../components/textEditorModal';
import LoadingContent from '../components/loadingContent';
import { DataState, reducer } from '../reducers/customTabListDetailsReducer';
import Notify from '../components/notify';

import { setTopicDetails, getTopicDetails, setTopicDetailsArray } from "../cacheStorage";
function CustomTabListDetails() {
    const loc = useLocation();
    const [topicDetails, dispatch] = React.useReducer(reducer, DataState);
    const { userdata } = React.useContext(GlobalContext);
    const [onSuccess, setOnsuccess] = React.useState(false);

    const [rowId, setRowId] = React.useState(0);
    const [isEdit, setIsEdit] = React.useState(false);
    const [modalDialogOpen, setModalDialogOpen] = React.useState(false);
    // const [Edit, setEdit] = React.useState({
    //     open: false,
    //     initData: ''
    // });
    console.log("CustomTabListDetails rendered");
    const savetoState = (data) => {
        dispatch({ type: 'ADD_DATA', payload: data })
    }
    const highlightall = () => {
        try {
            window.hljs.highlightAll();
            window.hljs.configure({ ignoreUnescapedHTML: true })
            if (window.globalTheme) {
                window.document.querySelector('.MuiTypography-root > div').setAttribute('class', "darkModeColor");
            }
        } catch (err) { }
    }

    const handleChangeConfig = (isAll, searchText = '') => {
        console.log(isAll, searchText);
        // let body = {
        //     fetchByDate: isDate,
        //     fetchDate: fetchDate !== '' ? new Date(fetchDate).toISOString().slice(0, 10) : '',
        //     fetchByKeyword: isTextSearch,
        //     likeString: likeString
        // };
        // if (isAll) {
        //     body = {
        //         pageNo: 1
        //     };
        //     setPageNo(1);
        // }
        // axios({
        //     method: 'post',
        //     url: ApiConfig.url + "/currentaffairs",
        //     data: body
        // }).then(function (response) {
        //     console.log(response);
        //     if (response.status === 200) {
        //         if (response.data.success === true) {
        //             console.log(response.data);
        //             setErrorMsg("");
        //             setTopic_data(response.data.data);
        //             matchDivColorOnChangeTheme();
        //             if (isAll) {
        //                 setPageNo(pageNo + 1);
        //                 setHasMore(true);
        //             } else {
        //                 setHasMore(false);
        //             }
        //             try {
        //                 if (localStorage.getItem('theme') === 'dark') {
        //                     window.document.querySelectorAll(".MuiTypography-root>div").forEach((element) => {
        //                         element.classList.add("darkModeDiv");
        //                     })
        //                 }
        //             } catch (err) { }
        //             setOnsuccess(!onSuccess);
        //         } else {
        //             setHasMore(false);
        //             setTopic_data([]);
        //             setOnsuccess(!onSuccess);
        //             setErrorMsg(response.data.msg);
        //         }
        //     }
        // }).catch(err => {
        //     console.log(err);
        //     setTopic_data([]);
        //     setErrorMsg("Unexpected error occured. Failed to fetch.")
        // })
    }


    React.useEffect(() => {
        let tab_id = userdata.routes.find(el => el[1] === '/' + loc.pathname.split('/')[1]);
        if (tab_id !== undefined) {
            let cachedata = getTopicDetails(loc.pathname);
            if (cachedata === undefined) {
                axios({
                    method: 'post',
                    url: ApiConfig.url + "/custompage" + loc.pathname,
                    data: {
                        user_id: userdata.user_id,
                        tab_id: tab_id[5]
                    }
                }).then(function (response) {
                    let pL = {};
                    if (response.status === 200 && response.data.success === true) {
                        pL = { data: response.data.data, thisTopic: response.data.topic_data, errMsg: "" };
                        console.log(response.data);
                    } else {
                        console.log(response.data);
                        pL = { data: [], thisTopic: response.data?.topic_data || {}, errMsg: response.data.msg };
                    }
                    dispatch({ type: 'SET_DATA', payload: pL });
                    setTopicDetails(loc.pathname, pL);
                    highlightall();
                }).catch(err => {
                    console.log(err);
                    dispatch({ type: 'SET_ERROR', payload: { errMsg: "Unexpected error occured.Failed to fetch." } });
                })
            } else {
                setTimeout(() => { dispatch({ type: 'SET_DATA', payload: cachedata }) }, 10);
                setTimeout(() => { highlightall() }, 50)
            }


        } else {
            dispatch({ type: 'SET_ERROR', payload: { errMsg: "No access." } });
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loc.pathname]);


    const onDelete = (id) => {
        setRowId(id);
        setIsEdit(false);
        setModalDialogOpen(true);
    }
    const onEdit = (id) => {
        console.log(id)
        setRowId(id);
        setIsEdit(true);
        dispatch({ type: 'SET_EDIT_DATA_AND_OPEN_MODAL', payload: topicDetails.topic_data.find(obj => obj.id === id).topic_details });
    }
    const cancelEdit = () => {
        dispatch({ type: 'CLOSE_EDIT_MODAL' });
        // setEdit({ ...Edit, open: false, success: false, Msg: '' });
    }
    const handleUpdate = (updatedData) => {
        console.log(updatedData)
        axios({
            method: 'put',
            url: ApiConfig.url + "/custompage" + loc.pathname + '/update/' + rowId,
            data: {
                paragraph: String(updatedData.replace(/\n/g, '')),
                user_id: userdata.user_id
            }
        }).then(function (response) {
            console.log(response.data);
            if (response.status === 200) {
                if (response.data.success === true) {
                    var deeptopic_data = _.cloneDeep(topicDetails.topic_data);
                    _.set(_.find(deeptopic_data, { id: rowId }), 'topic_details', updatedData);
                    setTopicDetailsArray(loc.pathname, deeptopic_data);
                    dispatch({ type: 'UPDATE_DATA', payload: { data: deeptopic_data } });
                    Notify().success(response.data.msg);
                } else {
                    dispatch({ type: 'TOGGLE_EDIT_MODAL_BUTTON_LOADING' });
                    Notify().error(response.data.msg);
                }
            }
        }).catch(err => {
            console.log(err);
            dispatch({ type: 'TOGGLE_EDIT_MODAL_BUTTON_LOADING' });
            Notify().error('Unexpected Error Occured');
        })
    }

    const handlePermit = (isPermitted) => {
        if (isPermitted) {
            axios({
                method: 'delete',
                url: ApiConfig.url + "/custompage" + loc.pathname + '/delete/' + rowId,
                data: {
                    user_id: userdata.user_id
                }
            }).then(function (response) {
                console.log(response);
                if (response.status === 200) {
                    if (response.data.success === true) {
                        Notify().success(response.data.msg);
                        let newArrayofObjs = topicDetails.topic_data.filter(item => item.id !== rowId);
                        if (newArrayofObjs.length > 0) {
                            dispatch({ type: 'UPDATE_DATA', payload: { data: newArrayofObjs } });
                            setTopicDetailsArray(loc.pathname, newArrayofObjs);
                        } else {
                            dispatch({ type: 'SET_DATA', payload: { data: [], errMsg: "No Data Found.", thisTopic: topicDetails.thisTopic } });
                        }
                    } else {
                        Notify().error(response.data.msg);
                    }
                }
            }).catch(err => {
                Notify().error('Unexpected Error Occured');
                console.log(err);
            })
            // handleEditOrDelete(isEdit, rowId);
        } else {
            console.log(isPermitted, isEdit, 'delete', rowId);
        }
        setModalDialogOpen(false);
    }
    return (
        <Container>
            <Box sx={{ textAlign: "center" }}>
                <h2>
                    {topicDetails.thisTopic.topic_name}

                    {
                        topicDetails.thisTopic.topic_name &&
                        <Badge badgeContent={topicDetails.thisTopic.isPrivate === 1 ? 'private' : 'public'} variant='string' color={topicDetails.thisTopic.isPrivate === 1 ? 'error' : 'success'} sx={{ marginLeft: 4, marginTop: -0.2, color: 'inherit' }} />
                    }


                </h2>

                <h4>
                    {topicDetails.thisTopic.topic_desc}
                </h4>
            </Box>
            <Box >

                <Divider light />
                <br />
                {
                    topicDetails.thisTopic.user_id === userdata.user_id ?
                        <CustomTabListDetailsTopBar tabId={userdata.routes.find(el => el[1] === '/' + loc.pathname.split('/')[1])[5]} handleChangeConfig={handleChangeConfig} savetoState={savetoState} onSuccess={onSuccess} /> : ''
                }
            </Box>
            {
                topicDetails.errMsg !== "" ?
                    <>
                        <Box>
                            <Alert variant='outlined' color='info' severity="info" sx={{ mt: 2 }}>
                                <strong>{topicDetails.errMsg}</strong>
                            </Alert>
                        </Box>
                    </> : (topicDetails.errMsg === "" && topicDetails.topic_data.length > 0) ?
                        <>

                            {

                                topicDetails.topic_data.map((data) => (
                                    <Paper elevation={2} key={data.id}>
                                        <Card sx={{ mt: 1 }} >
                                            <CardContent>
                                                <Typography align="right" sx={{ fontSize: 13 }} color="text.secondary" gutterBottom>
                                                    Last modified on {new Date(data.datetime).toLocaleString('en-GB', { hour12: true })}
                                                </Typography>
                                                <Divider sx={{ mb: 1 }} />
                                                <Typography variant="body1" align="justify" component="div">
                                                    {renderHTML(data.topic_details)}
                                                </Typography>

                                            </CardContent>
                                            <CardActions disableSpacing sx={{
                                                mx: 'auto',
                                                justifyContent: "flex-end"
                                            }}>
                                                {
                                                    topicDetails.thisTopic.user_id === userdata.user_id ?
                                                        <>
                                                            <Tooltip title="Edit">
                                                                <IconButton color="secondary"
                                                                    onClick={() => { onEdit(data.id) }} size="small" ><EditIcon /></IconButton>
                                                            </Tooltip>

                                                            <Tooltip title="Delete">
                                                                <IconButton color="error" size="small" onClick={() => { onDelete(data.id) }}><DeleteIcon /></IconButton>
                                                            </Tooltip></> : ''
                                                }

                                            </CardActions>

                                        </Card>
                                    </Paper>
                                ))
                            }
                            {
                                topicDetails.thisTopic.user_id === userdata.user_id ?
                                    <>
                                        <TextEditorModal open={topicDetails.editModalOpen} closeModal={cancelEdit} initData={topicDetails.editModalInitData} heading={'Update this passage'} buttonName={'Update'} handleSubmit={handleUpdate} toggle={topicDetails.editModalLoadToggle} />

                                        <ModalDialog title='Really want to delete?' open={modalDialogOpen} children='It will delete passage from the List' modalToParent={handlePermit} />
                                    </> : ''
                            }

                        </> :
                        (
                            // <TopicDetailsSkeleten total={3} />
                            <LoadingContent loaderType="circle" color="secondary" minHeight="200px" />
                        )
            }
        </Container>
    );
}

export default CustomTabListDetails;