import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { LoadingButton } from '@mui/lab';
import { TextField, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
function CustomTabListDetailsSettingsBar({ onSuccess, handleChangeConfig }) {
    console.log("settings bar rendered")
    const [searchByTest, setSearchByText] = React.useState('');
    const [loadingSearch, setloadingSearch] = React.useState(false);
    const [loadingSearchAll, setloadingSearchAll] = React.useState(false);
    const handleSearch = () => {
        if (searchByTest !== '') {
            handleChangeConfig(false, searchByTest);
            setloadingSearch(true);
        } else {
            window.alert("please input something to search.");
        }
    }
    const handleSearchAll = () => {
        setloadingSearchAll(true);
        handleChangeConfig(true);
    }
    React.useEffect(() => {
        setloadingSearch(false);
        setloadingSearchAll(false);
    }, [onSuccess]);
    return (< Box >
        <Card sx={{ width: '100%', marginTop: '10px' }}>
            <CardContent sx={{ textAlign: 'center' }}>
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ margin: "auto", justifyContent: 'space-oddly', }}>
                    <Grid item xs={12} sm={12} md={2} lg={2} sx={{ marginTop: "15px" }}>
                        <Typography variant="heading" >
                            Search with Keyword:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={6} >
                        <TextField
                            fullWidth
                            label="Search here"
                            id="standard-size-normal"
                            variant="standard"
                            value={searchByTest}
                            onChange={(e) => setSearchByText(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={5} sm={5} md={1.5} lg={2} >
                        <LoadingButton
                            onClick={handleSearch}
                            startIcon={<SearchIcon />}
                            loading={loadingSearch}
                            loadingPosition="start"
                            variant="contained"
                            sx={{ marginTop: "10px" }}
                        >
                            Search
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={5} sm={5} md={1.5} lg={2} >
                        <LoadingButton
                            color="secondary"
                            onClick={handleSearchAll}
                            loading={loadingSearchAll}
                            loadingPosition="start"
                            startIcon={<DensitySmallIcon />}
                            variant="contained"
                            sx={{ marginTop: "10px" }}
                        >
                            All
                        </LoadingButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Box>);
}

export default CustomTabListDetailsSettingsBar;