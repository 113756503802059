
// import ResponsiveDrawer from './components/navbar';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import Navbar1 from './pages/navbar1';
import { HashRouter } from "react-router-dom";
import { GlobalContextProvider } from './contexts/globalContext';



export default function App() {

    const [defTheme, setTheme] = React.useState("");
    // const [GlobalData,setGlobalData] = React.useState("this is app state value");
    React.useEffect(() => {
        let localtheme = window.localStorage.getItem("theme");
        setTheme(localtheme);
        window.globalTheme = defTheme;


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // const defTheme = useMediaQuery('(prefers-color-scheme: dark)');

    const handleTheme = () => {
        console.log("called");
        let th = (defTheme === "dark") ? "light" : "dark";
        setTheme(th);
        window.localStorage.setItem("theme", th);

        if (th === "dark") {
            window.document.querySelectorAll(".MuiTypography-root>div").forEach((element) => {
                element.classList.add("darkModeDiv");
            })
        } else {
            window.document.querySelectorAll(".MuiTypography-root>div").forEach((element) => {
                element.classList.remove("darkModeDiv");
            })
        }
    }

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: defTheme === 'dark' ? 'dark' : 'light',
                },
            }),
        [defTheme],
    );
    console.log("app rendered");
    localStorage.removeItem("topics_data");
    return (
        <>
            <ThemeProvider theme={theme}>
                <HashRouter>
                    <GlobalContextProvider >
                        {/* <SamplePage /> */}
                        <Navbar1 handleThemeChange={handleTheme} theme={defTheme} />

                    </GlobalContextProvider>
                </HashRouter>
            </ThemeProvider>
            {/* <ResponsiveDrawer /> */}
        </>
    );
}