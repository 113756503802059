/* eslint-disable no-unused-vars */
import Grid from '@mui/material/Grid';
import Topic from '../components/topic';
import { Alert, Container, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TopicsTopbar from '../components/topicsTopbar';
import React from 'react';
import ApiConfig from '../config/apiConfig';
import axios from 'axios';
import LoadingContent from '../components/loadingContent';
import ModalForm from '../components/modalform';
// import AlertModal from '../components/alertModal';
// import TopicsSkeleten from '../components/topicsSkeleten';
import { GlobalContext } from '../contexts/globalContext';
import ModalDialog from '../components/modalDialog';
import Notify from '../components/notify';
import { initState, TabListReducer, TabListEditReducer, editDataState } from '../reducers/customTabListReducer';


import { setTopicCache, getTopicCache } from "../cacheStorage";
export default function CustomTabList(props) {

  const [initTopicState, TopicsDispatch] = React.useReducer(TabListReducer, initState);
  const [editTopicData, editTopicDataDispatch] = React.useReducer(TabListEditReducer, editDataState);
  console.log("custom tab rendered")

  const { userdata } = React.useContext(GlobalContext);
  const [deleteTopicId, setDeleteTopicId] = React.useState(0);
  const [deletemodalOpen, setDeletemodalOpen] = React.useState(false);
  React.useEffect(() => {
    let t_id = props.data[1].slice(1);
    console.log('tab Data:', t_id)
    let cachedata = getTopicCache(t_id);
    console.log('topics data', cachedata)
    TopicsDispatch({ type: 'IS_LOADING', payload: { isLoading: true } });
    if (cachedata === undefined) {
      axios({
        method: 'POST',
        url: ApiConfig.url + "/custompage/getTopics/" + t_id,
        data: {
          user_id: userdata.user_id,
        }
      }).then(function (response) {
        console.log(response);
        let pL = {};
        if (response.status === 200 && response.data.success === true) {
          pL = { tab_data: response.data.tab_data, data: response.data.data, currentUserId: response.data.currentUserId, errMsg: "", isLoading: false };
        } else if (response.status === 200 && response.data.success === false) {
          pL = { data: [], errMsg: response.data?.no_data ? "" : response.data.msg, isLoading: false };
        }
        TopicsDispatch({ type: 'SET_ALL', payload: pL });
        setTopicCache(t_id, pL);
      }).catch(err => {
        console.log(err);
        TopicsDispatch({ type: 'SET_ERROR_AND_LOADING', payload: { errMsg: "Unexpected error occured. Failed to fetch.", isLoading: false } });
      })
    } else {
      TopicsDispatch({ type: 'SET_ALL', payload: cachedata });
      setTopicCache(t_id, cachedata);
      // setTimeout(() => { TopicsDispatch({ type: 'SET_ALL', payload: cachedata }) }, 0);
    }


  }, [props.data, userdata.user_id]);

  const handleNewTopicCreate = React.useCallback((topic_data_p) => {
    console.log("created data :", topic_data_p)
    let ass_to = topic_data_p.isPrivate === 1 ? topic_data_p.assigned_to.map(obj => obj.id) : [];
    axios({
      method: 'post',
      url: ApiConfig.url + "/topic/add",
      data: {
        tab_id: props.data[5],
        user_id: userdata.user_id,
        topic_name: topic_data_p.topic_name,
        topic_desc: topic_data_p.topic_desc,
        assigned_to: ass_to,
        isPrivate: topic_data_p.isPrivate
      }
    }).then(function (response) {
      console.log(response);
      if (response.status === 201 && response.data.success === true) {
        Notify().success(response.data.msg);
        let data = { id: response.data.last_id, user_id: parseInt(response.data.user_id), topic_name: topic_data_p.topic_name, topic_desc: topic_data_p.topic_desc, isPrivate: topic_data_p.isPrivate, datetime: response.data.datetime, assigned_to: ass_to };
        TopicsDispatch({ type: 'ADD_TOPIC_DATA', payload: { data: data, topic_id: props.data[1].slice(1) } });
      } else if (response.status === 200 && response.data.success === false) {
        Notify().error(response.data.msg);
      }
    }).catch(err => {
      Notify().error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTopicActionRequest = (isEdit = false, data) => {
    if (isEdit && data) {
      let payloadData = data;
      payloadData.isOpen = true;
      editTopicDataDispatch({ type: 'SET_EDIT_MODAL_DATA', payload: payloadData });
    } else {
      setDeleteTopicId(data);
      setDeletemodalOpen(true);
    }
  }
  const handleModalEditAction = () => {
    editTopicDataDispatch({ type: 'CLOSE_EDIT_MODAL' });

  }
  const handleModalDeletePermit = (isPermitted) => {
    setDeletemodalOpen(false);
    if (isPermitted) {
      axios({
        method: 'delete',
        url: ApiConfig.url + "/custompage/" + props.data[1].slice(1) + '/delete/' + deleteTopicId,
        data: {
          user_id: userdata.user_id,
        }
      }).then(function (response) {
        console.log(response);
        if (response.status === 200 && response.data.success === true) {
          Notify().success(response.data.msg);
          TopicsDispatch({ type: 'DELETE_TOPIC_DATA', payload: { id: deleteTopicId, topic_id: props.data[1].slice(1) } });
        } else if (response.status === 200 && response.data.success === false) {
          Notify().error(response.data.msg);
        }

      }).catch(err => {
        Notify().error(err);
        console.log(err);
      });
    }
  }

  const handleSaveData = (updatedData) => {
    editTopicDataDispatch({ type: 'CLOSE_EDIT_MODAL' });
    let ass_to = updatedData.isPrivate === 1 ? updatedData.assigned_to.map(obj => obj.id) : [];
    console.log('this place', updatedData, editTopicData.topic_id, ass_to);

    axios({
      method: 'put',
      url: ApiConfig.url + "/topic/update/" + editTopicData.topic_id,
      data: {
        user_id: userdata.user_id,
        topic_name: updatedData.topic_name,
        topic_desc: updatedData.topic_desc,
        assigned_to: ass_to,
        isPrivate: updatedData.isPrivate
      }
    }).then(function (response) {
      console.log(response);
      if (response.status === 200 && response.data.success === true) {
        Notify().success(response.data.msg);
        let data = { id: editTopicData.topic_id, topic_name: updatedData.topic_name, topic_desc: updatedData.topic_desc, isPrivate: updatedData.isPrivate, assigned_to: ass_to };

        TopicsDispatch({ type: 'UPDATE_TOPIC_DATA', payload: { data: data, topic_id: props.data[1].slice(1) } });
      } else if (response.status === 200 && response.data.success === false) {
        Notify().error(response.data.msg);
      }
    }).catch(err => {
      Notify().error(err);
    });


  }
  return (
    <>
      <Container>
        {
          initTopicState.errMsg !== "" ?
            <>

              <Alert color='error' severity="error" sx={{ mt: 2 }}>
                <strong>{initTopicState.errMsg}</strong>
              </Alert>
            </> : (initTopicState.errMsg === "" && !initTopicState.isLoading) ?
              (<>
                <ModalForm user_id={userdata.user_id} topic_id={editTopicData.topic_id} isPrivate={editTopicData.isPrivate} assigned_to={editTopicData.assigned_to} modalopen={editTopicData.isOpen} title={`Edit ${editTopicData.topic_name}`} topic_name_p={editTopicData.topic_name} topic_desc_p={editTopicData.topic_desc} setModalOpenParent={handleModalEditAction} savedataToParent={handleSaveData} saveOrUpdateButtonName="Update" />

                <ModalDialog title='Really want to delete this topic?' open={deletemodalOpen} children='It will delete this topic and related topic details.' modalToParent={handleModalDeletePermit} />

                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant='h5' gutterBottom sx={{ textDecoration: 'underline' }}>
                    {props.data[0]}
                  </Typography>
                </Box>
                <Box>
                  <TopicsTopbar createNewTopic_p={handleNewTopicCreate} user_id={userdata.user_id} />
                </Box>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {initTopicState.TopicData.length > 0 && initTopicState.TopicData.map((data, index) => (
                    <Grid container item xs={12} sm={12} md={4} key={index}>
                      <Topic currentUserId={initTopicState.currentUserId} handleTopicActionRequest={handleTopicActionRequest} data={data} link={`/${props.data[1].slice(1)}`} />
                    </Grid>
                  ))
                  }
                </Grid>
                {
                  initTopicState.TopicData.length === 0 &&
                  <Alert variant='outlined' color='info' severity="info" sx={{ mt: 4 }}>
                    <strong>No data found.</strong>
                  </Alert>
                }


              </>) :
              (
                // <TopicsSkeleten total={6} />
                <LoadingContent loaderType={"circle"} color='secondary' />
              )

        }
      </Container>
    </>
  );
}
