import { Container, Divider, Stack, Switch, TextField } from '@mui/material';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import MultipleTextInput from './multipleTextInput';
import ApiConfig from '../config/apiConfig';
import axios from 'axios';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import { initState, EditModalReducer } from '../reducers/modalFormReducer';
var timeoutId;


const ModalForm = React.memo(({ user_id, title, topic_name_p, topic_desc_p, assigned_to, isPrivate, modalopen, setModalOpenParent, savedataToParent, saveOrUpdateButtonName }) => {

    const [modalData, modalDataDispatch] = React.useReducer(EditModalReducer, initState);
    const [saveOrUpdate, setsaveOrUpdate] = React.useState(false);

    console.log("ModalForm rendered", assigned_to);

    const onSelectChange = (e, newVal) => {
        console.log(newVal)
        modalDataDispatch({ type: 'SET_ASSIGNED_USERS', payload: newVal });
        // setTopicDetails({ ...topicDetails, assigned_to: newVal });
    }

    const handleClose = () => {
        setsaveOrUpdate(false);
        setModalOpenParent(false);
    }
    const HandleSaveTopic = () => {
        setsaveOrUpdate(false);
        savedataToParent(modalData);
    }

    //search debouncing---------------------------------------

    const debounce = (func, delay = 200) => {
        return function () {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, arguments);
            }, delay);
        }
    }
    const handleChange = (e) => {
        modalDataDispatch({ type: 'SET_FIELD_VALUE', payload: [e.target.name, e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value] });
    }


    function search(searchText) {
        console.log('called', searchText, modalData.searchUsers); // true
        if (modalData.searchUsers.filter(obj => obj.full_name.toLowerCase().includes(searchText.toLowerCase())).length === 0) {
            modalDataDispatch({ type: 'SET_SEARCH_LOADING', payload: true });
            axios({
                method: 'get',
                url: ApiConfig.url + "/user/search?name=" + searchText + "&c_user_id=" + user_id,
            }).then(function (response) {
                if (response.data.success === true) {
                    modalDataDispatch({
                        type: 'SET_SEARCH_LOADING_AND_SEARCH_USERS', payload: {
                            isSearchLoading: false,
                            searchUsers: response.data.data
                        }
                    });
                    // setSearchUsers([...searchUsers, ...response.data.data]);
                    console.log(response);
                } else if (response.data.success === false) {
                    modalDataDispatch({
                        type: 'SET_SEARCH_LOADING_AND_SEARCH_USERS', payload: {
                            isSearchLoading: false,
                            searchUsers: []
                        }
                    });
                }

            }).catch(err => {
                console.log(err);
                modalDataDispatch({ type: 'SET_SEARCH_LOADING', payload: false });
                // setErrorMsg("Unexpected error occured. Failed to fetch.")
            })
        }
    }

    const debouncedSearch = debounce(search, 500);

    const handleSearchChange = (e) => {
        debouncedSearch(e.target.value);
    }


    React.useEffect(() => {
        if (modalopen) {
            console.log('modal opened', assigned_to !== null && JSON.parse(assigned_to).length);
            if (topic_name_p !== '') {
                if (assigned_to !== null && JSON.parse(assigned_to).length > 0) {
                    modalDataDispatch({
                        type: 'SET_SEARCH_LOADING_WITH_TOPIC_DETAILS', payload: {
                            isSearchLoading: true,
                            textLebel: 'Fetching assigned users...',
                            topic_name: topic_name_p,
                            topic_desc: topic_desc_p,
                            isPrivate: isPrivate
                        }
                    });

                    axios({
                        method: 'post',
                        url: ApiConfig.url + "/user/search/ById",
                        data: {
                            user_id: user_id,
                            user_id_array: assigned_to !== null ? JSON.parse(assigned_to) : [],
                        }
                    }).then(function (response) {
                        let ass_to = [];
                        console.log(response);
                        if (response.data.success === true) {
                            ass_to = response.data.data;
                        }
                        let modalData = {
                            isFetching: false,
                            textLebel: "Select users to share this Tab",
                            isSearchLoading: false,
                            assigned_to: ass_to,
                            searchUsers: ass_to
                        }
                        modalDataDispatch({ type: 'SET_MODAL_DATA', payload: modalData });

                    }).catch(err => {
                        console.log(err);
                        modalDataDispatch({ type: 'SET_MODAL_ERROR', payload: modalData });
                    })
                } else {
                    let modalData = {
                        isFetching: false,
                        textLebel: "Select users to share this Tab",
                        isSearchLoading: false,
                        topic_name: topic_name_p,
                        topic_desc: topic_desc_p,
                        isPrivate: isPrivate,
                        assigned_to: [],
                        searchUsers: []
                    }
                    modalDataDispatch({ type: 'SET_MODAL_DATA', payload: modalData });
                }

            } else {
                console.log('else rendered')
                let modalData = {
                    isFetching: false,
                    textLebel: "Select users to share this Tab",
                    isSearchLoading: false,
                    assigned_to: [],
                    searchUsers: []
                }
                modalDataDispatch({ type: 'SET_MODAL_DATA', payload: modalData });
            }

        }
        return () => {
            clearTimeout(timeoutId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topic_name_p]);

    return (
        <>
            <Container>
                <Dialog

                    fullWidth
                    open={modalopen}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {title}
                    </DialogTitle>
                    <Divider light />
                    <DialogContent>
                        <Stack spacing={2}>
                            <TextField
                                value={modalData.topic_name}
                                disabled={saveOrUpdate}
                                onChange={handleChange}
                                autoFocus
                                fullWidth
                                label="Topic Title"
                                name="topic_name"
                                variant="standard"
                            />
                            <TextField
                                value={modalData.topic_desc}
                                disabled={saveOrUpdate}
                                onChange={handleChange}
                                fullWidth
                                multiline
                                name="topic_desc"
                                label="Topic Description"
                                variant="standard"
                            />
                            <Stack direction="row" spacing={1} alignItems="center">
                                <PublicTwoToneIcon color={'success'} />Public&nbsp;
                                <Switch name='isPrivate' checked={modalData.isPrivate === 1} color="secondary" onChange={handleChange} />
                                <SecurityTwoToneIcon color={'error'} />Private&nbsp;
                            </Stack>
                            <MultipleTextInput fetchingAssignDetails={{ isFetching: modalData.isFetching, textLebel: modalData.textLebel }} handleSearchChange={handleSearchChange} initUsers={modalData.assigned_to} onSelectChange={onSelectChange} searchLoading={modalData.isSearchLoading} searchUsers={modalData.searchUsers} isPrivate={modalData.isPrivate} />
                        </Stack>
                    </DialogContent>
                    <Divider light />
                    <DialogActions sx={{ m: 2 }}>
                        <Button size="small" color="error" variant='contained' onClick={handleClose}>
                            Cancel
                        </Button>
                        <LoadingButton size="small" variant="contained" loading={saveOrUpdate} color="secondary" onClick={HandleSaveTopic} autoFocus>
                            {saveOrUpdateButtonName}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
})

export default ModalForm;