import { Card, CardActions, CardContent, Container, Grid, Skeleton, Typography } from "@mui/material";

function TopicsSkeleten({ total }) {
    return (
        <>
            <Container sx={{ pt: 4.5 }}>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {Array.from(Array(total)).map((_, i) => (
                        <Grid container item xs={12} sm={12} md={4} key={i}>
                            <Card sx={{ width: '100%', marginTop: '10px' }} variant="outlined" key={i}>
                                <CardContent sx={{ paddingTop: '22px' }}>
                                    <Skeleton animation="wave" variant='text' height={35} width={160} />
                                    <Skeleton animation="wave" variant='text' height={25} />
                                </CardContent>
                                <CardActions >
                                    <Typography
                                        sx={{ flexGrow: 1, pl: 1 }}
                                    >
                                        <Skeleton animation="wave" variant='text' width={220} />
                                    </Typography>
                                    <Skeleton animation="wave" variant="circular" width={30} height={30} sx={{ mr: 1 }} />
                                    <Skeleton animation="wave" variant="circular" width={30} height={30} sx={{ mr: 1 }} />
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
    );
}

export default TopicsSkeleten;