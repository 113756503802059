import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Card, Container } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import ProfileSettings from '../components/settingsPage/profileSettings';
import CustomTabSettings from './../components/settingsPage/customTabSettings';
import { TabList } from '@mui/lab';

export default function Settings() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container>
            <Box sx={{ textAlign: "center" }}>
                <h2>
                    Settings
                </h2>

            </Box>
            <Card>
                <Box >
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList textColor="secondary"
                                variant="scrollable"
                                indicatorColor="secondary"
                                scrollButtons
                                onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Profile Details" value='1' />
                                <Tab label="All Custom Tabs" value='2' />
                                <Tab label="User Assignment" value='3' />
                                <Tab label="Reset Password" value='4' />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <ProfileSettings />
                        </TabPanel>
                        <TabPanel value="2">
                            <CustomTabSettings />
                        </TabPanel>
                        <TabPanel value="3">User Assignment</TabPanel>
                        <TabPanel value="4">Reset Password</TabPanel>
                    </TabContext>
                </Box>
            </Card>
        </Container>
    );
}
