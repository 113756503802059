import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, LocalizationProvider, LoadingButton } from '@mui/lab';
import { TextField, Grid, Radio } from '@mui/material';
import Typography from '@mui/material/Typography';
import TodayIcon from '@mui/icons-material/Today';
import SearchIcon from '@mui/icons-material/Search';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
function CurrentAffairsSettingsBar({ onSuccess, handleChangeConfig }) {
    console.log("settings bar rendered")
    const [value, setValue] = React.useState(new Date());
    const [searchByTest, setSearchByText] = React.useState('');
    const [searchByDate, setSearchByDate] = React.useState(true);
    const [loadingSearchToday, setloadingSearchToday] = React.useState(false);
    const [loadingSearch, setloadingSearch] = React.useState(false);
    const [loadingSearchAll, setloadingSearchAll] = React.useState(false);

    const handleChange = (newValue) => {

        setValue(newValue);
        console.log(value)
    };
    const handleSearch = () => {
        if (searchByDate === true) {
            handleChangeConfig(true, value)
            setloadingSearch(true);
        } else {
            if (searchByTest !== '') {
                handleChangeConfig(false, '', true, searchByTest);
                setloadingSearch(true);
            } else {
                window.alert("please input something to search.")
            }
        }

    }
    const handleSearchToday = () => {
        handleChangeConfig(true, new Date())
        setloadingSearchToday(true);
    }
    const handleSearchAll = () => {
        setloadingSearchAll(true);
        handleChangeConfig(false, '', false, '', true);
    }
    React.useEffect(() => {
        setloadingSearch(false);
        setloadingSearchToday(false);
        setloadingSearchAll(false);
    }, [onSuccess]);
    return (< Box >
        <Card sx={{ width: '100%', marginTop: '10px' }}>
            <CardContent>
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ margin: "auto", justifyContent: 'space-oddly', }}>
                    <Grid item xs={4} sm={4} md={1} lg={2} sx={{ marginTop: "15px" }}>
                        <Typography variant="heading" >
                            Search:
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Radio
                            checked={searchByDate}
                            onClick={() => { setSearchByDate(true) }}
                            name="radio-buttons"
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                disabled={!searchByDate}
                                label="Search by Date"
                                inputFormat="MM/dd/yyyy"
                                value={value}
                                onChange={handleChange}
                                renderInput={(params) => <TextField {...params} />}
                                disableFuture
                            />

                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3} lg={4} >
                        <Radio
                            checked={!searchByDate}
                            onClick={() => { setSearchByDate(false) }}
                            name="radio-buttons"
                        />
                        <TextField
                            disabled={searchByDate}
                            label="Search by keyword"
                            id="standard-size-normal"
                            variant="standard"
                            value={searchByTest}
                            onChange={(e) => setSearchByText(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={1.5} lg={1} >
                        <LoadingButton
                            onClick={handleSearch}
                            startIcon={<SearchIcon />}
                            loading={loadingSearch}
                            loadingPosition="start"
                            variant="contained"
                            sx={{ marginTop: "10px" }}
                        >
                            Search
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={2} sm={2} md={1.25} lg={1} >
                        <LoadingButton
                            color="secondary"
                            onClick={handleSearchToday}
                            loading={loadingSearchToday}
                            loadingPosition="start"
                            startIcon={<TodayIcon />}
                            variant="contained"
                            sx={{ marginTop: "10px" }}
                        >
                            Today
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={2} sm={2} md={1.25} lg={1} >
                        <LoadingButton
                            color="secondary"
                            onClick={handleSearchAll}
                            loading={loadingSearchAll}
                            loadingPosition="start"
                            startIcon={<DensitySmallIcon />}
                            variant="contained"
                            sx={{ marginTop: "10px" }}
                        >
                            All
                        </LoadingButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Box>);
}

export default CurrentAffairsSettingsBar;