import { Card, CardActions, CardContent, Skeleton, Typography } from "@mui/material";

function TopicDetailsSkeleten({ total }) {
    return (
        <>
            {Array.from(Array(total)).map((_, i) =>
                <Card sx={{ width: '100%', marginTop: '10px', p: 0 }} variant='outlined' key={i}>
                    <CardContent sx={{ paddingTop: '22px' }}>
                        <Skeleton animation="wave" variant='text' height={25} />
                        <Skeleton animation="wave" variant='text' height={25} />
                        <Skeleton animation="wave" variant='text' height={25} />
                        <Skeleton animation="wave" variant='text' height={25} />
                        <Skeleton animation="wave" variant='text' height={25} />
                    </CardContent>
                    <CardActions >
                        <Typography
                            sx={{ flexGrow: 1, pl: 1 }}
                        >
                        </Typography>
                        <Skeleton animation="wave" variant="circular" width={30} height={30} sx={{ mr: 2 }} />
                        <Skeleton animation="wave" variant="circular" width={30} height={30} sx={{ mr: 1 }} />
                    </CardActions>
                </Card>
            )
            }
        </>
    );
}

export default TopicDetailsSkeleten;