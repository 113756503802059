import * as React from 'react';
import { Alert, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { GlobalContext } from './../contexts/globalContext';
import ApiConfig from '../config/apiConfig';
import axios from 'axios';
import { Box } from '@mui/system';


export default class LoginForm extends React.Component {
    _isMounted = false;
    static contextType = GlobalContext;

    state = {
        formData: {
            user_name: '',
            user_pass: '',
            showPassword: false,
        },
        submitted: false,
        ErrMsg: ''
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }




    handleChange = (event) => {
        const { formData } = this.state;
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    }

    handleSubmit = () => {
        // console.log(this.props.location.pathname)
        this.setState({ submitted: true }, () => {
            let globalObj = this;
            if (globalObj._isMounted) {
                axios({
                    method: 'post',
                    url: ApiConfig.url + "/user/login",
                    data: this.state.formData
                }).then(function (response) {
                    if (response.status === 200) {
                        console.log(response)
                        if (response.data.success === true) {
                            window.localStorage.setItem("token", response.data.token)
                            window.localStorage.setItem("expireAt", response.data.expireAt)
                            globalObj.context.setUserData({
                                user_id: response.data.user_id,
                                user_name: globalObj.state.formData.user_name,
                                user_email: response.data.user_email,
                                full_name: response.data.full_name,
                                datetime: response.data.datetime,
                                login: true,
                                totalMails: 2,
                                routes: response.data.routes
                            })
                            if (globalObj.props.location.pathname === "/login") {
                                globalObj.props.redirect()
                            }
                            // const navigate = useNavigate();
                            // globalObj.setState({ submitted: false });
                        } else {
                            globalObj.setState({ submitted: false, ErrMsg: response.data.msg });
                            console.log("Login Failed");
                        }
                    } else {
                        globalObj.setState({ submitted: false, ErrMsg: "Login Failed" });
                        console.log("Login Failed");
                    }
                }).catch(err => {
                    globalObj.setState({ submitted: false, ErrMsg: "Unexpected Error Occured:", err });
                })
            }

        });
    }
    handleClickShowPassword = () => {
        const { formData } = this.state;
        formData['showPassword'] = !formData['showPassword'];
        this.setState({ formData });
    }

    render() {
        const { ErrMsg, formData, submitted } = this.state;
        return (
            <ValidatorForm
                ref="form"
                onSubmit={this.handleSubmit}
            >
                <TextValidator
                    fullWidth
                    disabled={submitted}
                    variant="standard"
                    label="User Name"
                    onChange={this.handleChange}
                    name="user_name"
                    value={formData.user_name}
                    validators={['required']}
                    errorMessages={['User Name is required']}

                />
                <br />
                <TextValidator
                    fullWidth
                    disabled={submitted}
                    label="Password"
                    variant="standard"
                    onChange={this.handleChange}
                    name="user_pass"
                    value={formData.user_pass}
                    type={formData.showPassword ? "text" : "password"} // <-- This is where the magic happens
                    InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                >
                                    {formData.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    validators={['required']}
                    errorMessages={['Password is required']}
                >
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                        >
                            {formData.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                </TextValidator>

                <br />
                <LoadingButton
                    type="submit"
                    fullWidth
                    color="secondary"
                    loading={submitted}
                    variant="contained"
                >
                    Login
                </LoadingButton>
                <Box sx={{ mt: 2 }}>
                    {
                        ErrMsg !== '' &&
                        <Alert severity="error">
                            {ErrMsg}
                        </Alert>
                    }


                </Box>
            </ValidatorForm>
        );
    }
}