/* eslint-disable no-unused-vars */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import Badge from '@mui/material/Badge';

import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';

import CssBaseline from '@mui/material/CssBaseline';
import { Button } from '@mui/material';
import Form from '../pages/form';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Routes, Route, Link, useLocation } from "react-router-dom";
import MaterialUISwitch from './../config/muiThemeSwitch';

import { GlobalContext } from '../contexts/globalContext';
import AccountMenu from './../components/accountMenu';
import ApiConfig from './../config/apiConfig';
import axios from 'axios';
import Sidebar from './../components/sidebar';
import CodeEditor from './codeEditor';
import CurrentAffairs from './currentAffairs';
import Topics from './topics';
import TopicDetails from './topicDetails';
import IndexPage from './IndexPage';
import NotFoundPage from './notFound';
import CustomTabList from './customTabList';
import CustomTabListDetails from './customTabListDetails';
import Settings from './settings';
import { clearAll } from '../cacheStorage';
function Navbar1({ handleThemeChange, theme }) {
    const location = useLocation();
    const { userdata, setUserData } = React.useContext(GlobalContext);

    const getKeyByValue = (value) => {
        return userdata.routes.find(arr => (arr[1] === value || value.includes(arr[1].toLowerCase())))?.[0] || 'Codes';
    }
    const handleLogout = () => {
        window.localStorage.clear();
        clearAll();
        setUserData(prevData => ({ ...prevData, login: false, routes: [] }));
    }
    React.useLayoutEffect(() => {
        if (userdata.login === false) {
            document.title = 'Login';
        } else if (location.pathname === '/settings') {
            document.title = 'Settings';
        } else if (location.pathname !== '/login') {
            //change document title name
            document.title = getKeyByValue(location.pathname);
        }
        // Scroll to top if path changes
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, userdata.login]);
    console.log("navbar1 rendered");
    React.useEffect(() => {

        const getData = async () => {
            await axios({
                method: 'post',
                url: ApiConfig.url + "/user/checkuser",
                headers: {
                    'Authorization': "Bearer " + window.localStorage.getItem("token")
                }
            }).then(function (response) {
                if (response.status === 200) {
                    console.log(response)
                    if (response.data.success === true) {
                        setUserData({
                            user_id: response.data.data.data.user_id,
                            user_name: response.data.data.data.user_name,
                            user_email: response.data.data.data.user_email,
                            full_name: response.data.data.data.full_name,
                            datetime: response.data.data.data.datetime,
                            login: true,
                            totalMails: 2,
                            routes: response.data.routes
                        })
                    } else {
                        setUserData({
                            user_id: 0,
                            user_name: '',
                            user_email: '',
                            full_name: '',
                            datetime: '',
                            login: false,
                            totalMails: 2,
                            routes: []
                        });
                        window.localStorage.setItem("token", "");
                        window.localStorage.setItem("expireAt", "");
                    }
                } else {
                    setUserData({
                        user_id: 0,
                        user_name: '',
                        user_email: '',
                        full_name: '',
                        datetime: '',
                        login: false,
                        totalMails: 2,
                        routes: []
                    });
                    window.localStorage.setItem("token", "");
                    window.localStorage.setItem("expireAt", "");
                }
            }).catch(err => {
                window.localStorage.setItem("token", "");
                window.localStorage.setItem("expireAt", "");
                console.log("Unexpected Error Occured:", err);
            })
        }
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getComponent = (arr) => {
        switch (arr[3]) {
            case 'CurrentAffairs':
                return <CurrentAffairs handleLogout={handleLogout} />;
            case 'Topics':
                return <Topics data={arr} />;
            case 'TopicDetails':
                return <TopicDetails />;
            case 'CodeEditor':
                return <CodeEditor />;
            case 'CustomTabList':
                return <CustomTabList data={arr} />;
            case 'CustomTabListDetails':
                return <CustomTabListDetails data={arr} />;
            default:
                return <IndexPage />;
        }
    }



    return (
        <Box>
            <CssBaseline />
            <AppBar color='secondary' >
                <Toolbar >
                    <Sidebar theme={theme} handleThemeChange={handleThemeChange} />
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={userdata.totalMails} color="error">
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <Badge badgeContent={userdata.totalMails} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>

                    </Box>
                    <FormGroup sx={{ display: { lg: "block", sm: "none", md: "none", xs: "none" } }}>
                        <FormControlLabel
                            checked={(theme === "dark") ? true : false}
                            control={<MaterialUISwitch sx={{ ml: 1 }} />}
                            label=""
                            onChange={handleThemeChange}
                        />
                    </FormGroup>
                    {
                        userdata.login === false ?
                            <>
                                <Link to={`login`}>
                                    <Button variant="text" sx={{ color: 'white' }}>Login</Button>
                                </Link>
                            </> :
                            <>
                                <AccountMenu handleLogout={handleLogout} firstLetterOfName={userdata.user_name.charAt(0)} />
                            </>
                    }
                </Toolbar>
            </AppBar>
            <Box sx={{ marginTop: "100px" }}></Box>
            <Routes>
                {
                    userdata.login === false ?
                        <>
                            <Route exact path="/*" element={<Box sx={{ justifyContent: 'center', display: 'flex', p: 3 }} > <Form tabActive="1" /></Box>} />

                        </> :
                        <>
                            <Route exact path={`/login`} element={<Box sx={{ justifyContent: 'center', display: 'flex', p: 3 }} > <Form tabActive="1" /></Box>} />

                            <Route exact path={`/register`} element={<Box sx={{ justifyContent: 'center', display: 'flex', p: 3 }} > <Form tabActive="2" /></Box>} />

                            <Route exact path={`/index`} element={<IndexPage />} />
                            {
                                userdata.routes.length !== 0 &&
                                userdata.routes.map((data, index) => {
                                    return <Route exact path={data[1]} key={index} element={getComponent(data)} />
                                })

                            }
                            <Route exact path='/settings' element={<Settings />} />
                            <Route exact path='/' element={<IndexPage />} />
                            {

                            }
                            {/* <Route path='*' element={<NotFoundPage />} /> */}
                        </>
                }
            </Routes>
        </Box >
    );
}

export default Navbar1;