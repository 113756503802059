export const DataState = {
    editModalOpen: false,
    editModalLoadToggle: true,
    editModalInitData: "",
    errMsg: '',
    topic_data: [],
    thisTopic: {},
};

export function reducer(state, action) {
    switch (action.type) {
        case 'SET_DATA':
            return {
                errMsg: action.payload.errMsg,
                topic_data: action.payload.data,
                thisTopic: action.payload.thisTopic
            };
        case 'UPDATE_DATA':
            return {
                ...state,
                topic_data: action.payload.data,
                editModalOpen: false,
                editModalInitData: ''
            };
        case 'ADD_DATA':
            return {
                ...state,
                errMsg: '',
                topic_data: [...state.topic_data, action.payload],
            };
        case 'SET_ERROR':
            return {
                errMsg: action.payload.errMsg,
                topic_data: [],
                thisTopic: {}
            };
        case 'SET_EDIT_DATA_AND_OPEN_MODAL':
            return {
                ...state,
                editModalOpen: true,
                editModalInitData: action.payload,
            };
        case 'CLOSE_EDIT_MODAL':
            return {
                ...state,
                editModalOpen: false,
                editModalInitData: '',
            };
        case 'TOGGLE_EDIT_MODAL_BUTTON_LOADING':
            return {
                ...state,
                editModalLoadToggle: !state.editModalLoadToggle
            };
        default:
            return DataState;
    }
}