/* eslint-disable eqeqeq */
import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Badge from '@mui/material/Badge';
export default function Topic({ currentUserId, data, handleTopicActionRequest, link }) {
    const navigate = useNavigate();
    const redirectToDetails = (id) => {
        navigate(link + '/' + id, { state: { topic_name: data.topic_name, topic_desc: data.topic_desc } });
    }
    return (
        <>

            <Card sx={{ width: '100%', marginTop: '10px' }}>
                <CardActionArea onClick={() => {
                    redirectToDetails(data.id)
                }}>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                            {data.topic_name}

                            <Badge badgeContent={data.isPrivate == 1 ? 'private' : 'public'} variant='string' color={data.isPrivate == 1 ? 'error' : 'success'} sx={{ marginLeft: 4, color: 'inherit' }} />

                            {
                                data.user_id !== parseInt(currentUserId) &&
                                <Badge badgeContent={'shared'} variant='string' color={'info'} sx={{ marginLeft: 7, color: 'inherit' }} />
                            }
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {data.topic_desc}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions >
                    <Typography
                        variant="subtitle2"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        Created on {new Date(data.datetime).toLocaleString('en-GB', { hour12: true })}
                    </Typography>
                    {
                        data.user_id === parseInt(currentUserId) &&
                        (
                            <>
                                <IconButton color="secondary" size="small" onClick={() => {
                                    //editmode| true
                                    handleTopicActionRequest(true, data)
                                }}><EditIcon /></IconButton>
                                <IconButton color="error" size="small" onClick={() => {
                                    //deletemode| false
                                    handleTopicActionRequest(false, data.id)
                                }}><DeleteIcon /></IconButton>
                            </>
                        )
                    }

                </CardActions>
            </Card>
        </>
    );
}
