import React from 'react';
import MobileMenu from './mobileMenu';
import DesktopMenu from './desktopMenu';
function Sidebar(props) {
    const [isMobile, setIsMobile] = React.useState(false);
    React.useEffect(() => {
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        console.log(isMobile);
        if (isMobile) {
            setIsMobile(true);
        }
    }, []);
    return (
        <>
            {
                isMobile && <MobileMenu theme={props.theme} handleThemeChange={props.handleThemeChange} />
            }
            {
                !isMobile && <DesktopMenu />
            }
        </>
    );
}

export default React.memo(Sidebar);