import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import renderHTML from 'react-render-html';
export default function CurrentAffair({ cfData, onDelete, onEdit }) {
    return (
        <Card sx={{ width: '100%', marginTop: '10px', p: 0 }} variant="outlined">
            <CardContent sx={{ marginBottom: '22px' }}>
                <Typography component={'div'}>
                    {renderHTML(cfData.paragraph)}
                </Typography>
            </CardContent>
            <CardActions >
                <Typography
                    variant="subtitle2"
                    noWrap
                    component="div"
                    sx={{ flexGrow: 1 }}
                >
                    Last modified on {new Date(cfData.datetime).toLocaleString('en-GB', { hour12: true })}
                </Typography>
                <IconButton variant="outlined" color="secondary" size="small" onClick={() => onEdit(cfData.id)} ><EditIcon /></IconButton>
                <IconButton color="error" size="small" onClick={() => onDelete(cfData.id)}><DeleteIcon /></IconButton>
            </CardActions>
        </Card>
    );
}
