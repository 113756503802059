import { Link } from 'react-router-dom';
import './notFound.css';
import { Button, CssBaseline, Typography, Container, Box } from '@mui/material';
function NotFoundPage() {
    return (
        <><CssBaseline />
            <Container>
                <Box sx={{ height: '100vh', textAlign: 'center' }}>
                    <Typography variant="h3" component="div" gutterBottom>
                        404
                    </Typography>
                    <Typography variant="body1" component="div" gutterBottom>
                        The page you are searching is not available.
                    </Typography>
                    <Link to={'/index'}><Button color='inherit'>Go To Home</Button></Link>
                </Box>
            </Container>

        </>
    );
}

export default NotFoundPage;