import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte
function Notify() {
    return new Notyf({
        duration: 2000,
        position: {
            x: 'right',
            y: 'top',
        },

    });
}

export default Notify;